import { forEach } from '@angular-devkit/schematics';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { wkf } from 'src/app/models/global.model';
import { ProjetService } from 'src/app/service/projet.service';

import * as xlsx from 'xlsx';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-excel-handler',
  templateUrl: './excel-handler.component.html',
  styleUrls: ['./excel-handler.component.css']
})
export class ExcelHandlerComponent implements OnInit {

  @ViewChild('epltable', { static: false }) epltable: ElementRef;
  public excelHeaders: string[] = ["RRF", "Raison social", "Nom usuel", "DR"];
  public templateToExcel: string[][];
  public selectProjet: any;
  public nomProjet: string;
  public workflow: any = [];
  public dataWkf: Array<object> = [];
  public storeData: any;
  public jsonData: any;
  public fileUploaded: File;
  public worksheet: any;

  constructor(private router: Router,
    private prjSer: ProjetService) {
    this.router.getCurrentNavigation().extras.state;
  }

  ngOnInit() {
    this.nomProjet = sessionStorage.getItem("nomProjet");
    if (this.nomProjet) {
      let projet = {
        name: this.nomProjet.trim()
      }
      this.initialCalls(projet);

    }

  }


  initialCalls(projet) {
    this.prjSer.getWkf(projet).subscribe((res) => {
      this.workflow = res;

      this.prjSer.getProjet(projet).subscribe((res) => {
        this.selectProjet = res;
        this.exportTemplateAsExcel();
      });
    });
  }

  exportToExcel() {
    const ws: xlsx.WorkSheet = xlsx.utils.aoa_to_sheet(this.templateToExcel);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.sheet_add_json(ws, this.dataWkf, { skipHeader: true, origin: "A2" })
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, "test" + ".xlsx");
  }

  exportTemplateAsExcel() {
    let excelHeaders = [];
    let ev = this.selectProjet['event_list'];
    ev.forEach(event => {
      excelHeaders.push(event.name + ' : ' + event.description)
      let at = event.attributes;
      at.forEach(attr => {
        excelHeaders.push(event.name + ' : ' + attr.label);
      });

    });

    this.excelHeaders = this.excelHeaders.concat(excelHeaders);
    let data = this.excelHeaders.reduce((a, b) => (a[b] = '', a), {});
    let rrfSet = [];
    this.workflow.forEach((wkf) => {
      rrfSet.push(wkf.id_rrf);
    });

    rrfSet = [...new Set(rrfSet)];

    let temp = [];
    for (let i = 0; i < rrfSet.length; i++) {
      let result: object = {};
      result = this.getDatawkf(data, rrfSet[i], excelHeaders);
      temp.push(Object.assign({}, result));
    }
    // this.dataWkf = tempdatwkf;
    this.dataWkf = temp;
    this.templateToExcel = [this.excelHeaders, []];

  }

  getDatawkf(data, rrf, excelHeaders) {
    this.workflow.forEach(wkf => {
      if (wkf.id_rrf == rrf) {
        data["RRF"] = rrf;
        data["Raison social"] = wkf.raison_social.trim();
        data["Nom usuel"] = wkf.nom_usuel.trim();
        data["DR"] = wkf.dr.trim();
        data[wkf.event_attributes.name + ' : ' + wkf.event_attributes.description] = wkf.event_attributes.currentStatus;

        let at = wkf.event_attributes.attributes
        at.forEach((attr, i) => {
          excelHeaders.forEach(head => {
            if (wkf.event_attributes.name + ' : ' + attr.label == head) {
              data[head] = wkf.event_attributes.attributes[i].value
            }
          });
        });

      }
    });
    return data;
  }

  uploadedFile(event) {
    this.fileUploaded = event.target.files[0];
    this.readExcel();
  }

  readExcel() {
    let readFile = new FileReader();
    readFile.onload = (e) => {
      this.storeData = readFile.result;
      var data = new Uint8Array(this.storeData);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = xlsx.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[first_sheet_name];
    }
    readFile.readAsArrayBuffer(this.fileUploaded);
  }

  readAsJson() {

    let excelHeaders = [];
    let ev = this.selectProjet[0]['event_list'];
    ev.forEach(event => {
      excelHeaders.push(event.name + ' : ' + event.description)
      let at = event.attributes;
      at.forEach(attr => {
        excelHeaders.push(event.name + ' : ' + attr.label);
      });

    });



    this.jsonData = xlsx.utils.sheet_to_json(this.worksheet, { raw: false });
    let obj = {
      hearder: excelHeaders,
      excelData: this.jsonData,
      pgData: this.workflow,
      nomProjet: this.nomProjet
    }
    this.prjSer.updateWkf(obj).subscribe((res) => {
      let projet = {
        name: this.nomProjet.trim()
      }
      this.excelHeaders = ["RRF", "Raison social", "Nom usuel", "DR"];
      this.initialCalls(projet);
    });

  }

}
