import { Component, OnInit } from '@angular/core';
import { AuthService } from "../auth.service";
import { ActivatedRoute, Router } from "@angular/router";


@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})
export class CallbackComponent implements OnInit {
  private authCode: any = '';
  private authReturn = false;
  private arcaAccess = false;
  private dealerAccess = false;
  private userContext = [];
  private selecteProfile: any;
  private showvalidated = false;
  private loading = true;
  private authRes: any;
  authDealersDisplayed = false;

  constructor(private authService: AuthService, private activatedRoute: ActivatedRoute, private router: Router) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.authCode = params['code'];
      !this.authCode || this.authCode === '' ? this.router.navigate(['login']) : undefined;
    });
  }

  ngOnInit(): void {
    this.getToken();
  }

  handleCollapse() {
    this.authDealersDisplayed = !this.authDealersDisplayed;
  }

  profileRadioSelected() {
    console.log(this.selecteProfile);
    this.showvalidated = true
  }

  doAuthArca() {
    this.authRes['authType'] = 'centrale'
    this.authService.checkAccesRights(this.authRes)
  }

  doAuthDealer() {
    this.authRes['authType'] = 'dealer'
    this.authRes['dealerProfile'] = this.selecteProfile
    this.authService.checkAccesRights(this.authRes)
  }

  getToken() {
    this.authService.validateCode(this.authCode).subscribe((data: any) => {
      const res = data;
      console.log(res)
      if (res && res.hasOwnProperty('id_token')) {
        this.authReturn = true;
        this.authRes = res;
        if (res.arcaAccess && res.dealerAccess) {
          this.arcaAccess = true;
          this.dealerAccess = true
          this.userContext = res.usersContext;
          this.loading = false;
        }

        if (res.arcaAccess && (res.dealerAccess === false || res.dealerAccess == undefined)) {
          this.loading = false;
          this.doAuthArca()
        }

        if (res.dealerAccess && (res.arcaAccess == false || res.arcaAccess == undefined)) {
          this.dealerAccess = true;
          this.userContext = res.usersContext;
          this.loading = false;
        }
        sessionStorage.setItem('authRes', JSON.stringify(this.authRes))
      }
      else {
        console.log('erreur : ', res);
        sessionStorage.setItem("userIsAuthorized", "false");
        this.router.navigate(['/login'], { queryParams: { 'status': 'access_denied' }, skipLocationChange: true });
      }
    }, error => {
      console.log('Error while validating token', error);
      if (error && error.status === 401) {
        sessionStorage.setItem("userIsAuthorized", "false");
        this.router.navigate(['/login'], { queryParams: { 'status': 'access_denied' } });
      }
    });
  }
}
