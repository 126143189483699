import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userType:any={};
  private currUser:any={};
  private userTypeSubject = new BehaviorSubject(this.userType);
  private currUserSubject = new BehaviorSubject(this.userType);
  userTypeObservable = this.userTypeSubject.asObservable();
  currUserObservable = this.currUserSubject.asObservable();

  constructor() { }

  userTypeHasChanged(usertype) {
    this.userTypeSubject.next(usertype);
  }

  userHasChanged(currentUser) {
    this.currUserSubject.next(currentUser);
  }
}
