import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ProjetService } from "../../service/projet.service";
import { Router } from "@angular/router";
import { UntypedFormBuilder } from "@angular/forms";
import * as _ from "underscore";
import { Location } from '@angular/common';
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { Ng2SearchPipe } from "ng2-search-filter";
@Component({
  selector: "app-tab-details",
  templateUrl: "./tab-details.component.html",
  styleUrls: ["./tab-details.component.css"],
})
export class TabDetailsComponent implements OnInit {
  p: number = 1;
  numItemsPage: number = 20;
  public statusAdvancedList = [];
  public eventStatusList = [];
  public filteredList = [];
  public projectId: any;
  globalProjectEnteties = [];
  globalGroupPlaqueList = [];
  project: any;
  term: any;
  drsList: any = [];
  groupeList: any = [];
  plaqueList: any = [];
  drsSelectedList = [];
  groupeSelectedList = [];
  plaqueSelectedList = [];
  groupeGlobalList = [];
  plaqueGlobalList = [];
  rrfGroupeList = [];
  rrfPlaqueList = [];
  childObj = {};
  private readonly userType: any;
  dropdownSettingsNature: IDropdownSettings = {
    searchPlaceholderText: 'Recherche...',
    noDataAvailablePlaceholderText: 'pas de dépendances disponibles',
    noFilteredDataAvailablePlaceholderText: "pas de donnés disponibles",
    idField: 'libelle_nature',
    textField: 'libelle_nature',
    selectAllText: 'Tout sélectionner',
    unSelectAllText: 'Tout déselectionner',
    itemsShowLimit: 10,
    allowSearchFilter: true,
    enableCheckAll: true
  };
  dropdownSettingsPartenaire: IDropdownSettings = {
    searchPlaceholderText: 'Recherche...',
    noDataAvailablePlaceholderText: 'pas de dépendances disponibles',
    noFilteredDataAvailablePlaceholderText: "pas de donnés disponibles",
    idField: 'code_dr',
    textField: 'direction_regionale',
    selectAllText: 'Tout sélectionner',
    unSelectAllText: 'Tout déselectionner',
    itemsShowLimit: 10,
    allowSearchFilter: true,
    enableCheckAll: true
  };
  dropdownSettingsGroupe: IDropdownSettings = {
    searchPlaceholderText: 'Recherche...',
    noDataAvailablePlaceholderText: 'pas de dépendances disponibles',
    noFilteredDataAvailablePlaceholderText: "pas de donnés disponibles",
    idField: 'groupe',
    textField: 'groupe',
    selectAllText: 'Tout sélectionner',
    unSelectAllText: 'Tout déselectionner',
    itemsShowLimit: 10,
    allowSearchFilter: true,
    enableCheckAll: true
  };
  dropdownSettingsPlaque: IDropdownSettings = {
    searchPlaceholderText: 'Recherche...',
    noDataAvailablePlaceholderText: 'pas de dépendances disponibles',
    noFilteredDataAvailablePlaceholderText: "pas de donnés disponibles",
    idField: 'plaque_ou_pole',
    textField: 'plaque_ou_pole',
    selectAllText: 'Tout sélectionner',
    unSelectAllText: 'Tout déselectionner',
    itemsShowLimit: 10,
    allowSearchFilter: true,
    enableCheckAll: true
  };
  userUid: string;
  pipe = new Ng2SearchPipe();
  naturesList: any = [];
  natureSelectedList: any = [];
  drsOriginList: any[];
  natureOriginList: any[];
  avancements: any = [{ label: "Verrouillé", css: "locked", checked: false }, { label: "En Cours", css: "in-progress", checked: false }, { label: "Validé", css: "validated", checked: false }, { label: "Refusé", css: "refused", checked: false }]
  events: any;
  selectedStatus: any;
  idRrf: any;
  eventProfiles: any;

  constructor(
    private fb: UntypedFormBuilder,
    private projectService: ProjetService,
    private router: Router,
    private _location: Location,
    private cd: ChangeDetectorRef
  ) {
    const state = this.router.getCurrentNavigation().extras.state;
    if (state?.projectId && state.userType) {
      this.projectId = state.projectId;
      this.userType = state.userType;
      this.idRrf = state.idRrf;
    } else {
      this.router.navigateByUrl('project/home');
    }
    this.userUid = sessionStorage.getItem('userUid');
  }

  backClicked() {
    this._location.back();
  }
  updateprojectEvnets(res: any, projectEvents: any) {
    for (let entity of projectEvents) {
      if (entity.code_entite === res['result'][0].id_rrf) {
        for (let ev of entity.events) {
          let results = res['result'].filter(r => ev.project_event_id === r.project_event_id);
          if (results != null) {
            ev.current_status = results[0].current_status;
            this.cd.detectChanges();
          }
        }
      }
    }
  }
  ngOnInit() {
    this.projectService.event$.subscribe(ids => {
      if (ids.length > 0 && this.project) {
        const query = '(' + ids.map(id => `'${id}'`).join(',') + ')';
        this.projectService.getProjectEventsByIds(query).then((res): any => {
          this.updateprojectEvnets(res, this.project.projectEntities);
          this.updateprojectEvnets(res, this.globalProjectEnteties);
        })
      }
    })
    this.getProject();
  }

  getProject() {
    this.projectService.getProjectEntitiesDetails(this.projectId, this.userUid, this.userType, this.idRrf, this.p).then(response => {
      if (response?.result) {
        this.project = response.result;
        this.globalProjectEnteties.push(...response.result['projectEntities']);
        for (let en of response.result['projectEntities']) {
          let obj = { code_dr: en['code_dr'].trim(), direction_regionale: en['direction_regionale'].trim() };
          this.drsList.push(obj);
          this.naturesList.push({ libelle_nature: en.libelle_nature })
        }
        this.drsList = [...new Map(this.drsList.map((item, key) => [item['code_dr'], item])).values()];
        this.drsOriginList = [...this.drsList];
        this.naturesList = [...new Map(this.naturesList.map((item, key) => [item['libelle_nature'], item])).values()];
        this.natureOriginList = [...this.naturesList];
        this.events = this.globalProjectEnteties[0].events;
        this.events.forEach(e => {
          e.avancements = JSON.parse(JSON.stringify(this.avancements));
        })
        if (this.drsList.length) {
          let query = '(' + this.drsList.map(dr => `'${dr.direction_regionale}'`).join(',') + ')';
          this.projectService.findGroupPlaqueForDr(query).then(res => {
            this.globalGroupPlaqueList = res;
          });
        }
        this.getAllGroupePlaque();
      }
    });
  }

  getAllGroupePlaque() {
    let entities = this.globalProjectEnteties.map(e => e.code_entite);
    if (this.userType == 'admin') {
      entities = undefined;
    }
    this.projectService.getByGroupeOrPlaque("groupe", entities).then(res => {
      this.groupeList = res;
      this.groupeGlobalList.push(...res);
    })
    this.projectService.getByGroupeOrPlaque("plaque", entities).then(res => {
      this.plaqueList = res;
      this.plaqueGlobalList.push(...res);
    })

  }

  goToEntityEvents(event, entity) {
    this.childObj = { projectId: this.projectId, idRrf: entity.code_entite, userType: this.userType }
    entity.isClicked = true;
    // this.router.navigateByUrl("/project/events/details", {state: {projectId: this.projectId, idRrf: entity.code_entite, userType: this.userType}});
  }

  getEntityEventsProfile(entity) {
    this.projectService.getProjectEntityEvents(this.projectId, entity.code_entite).then(response => {
     this.eventProfiles=response.result;
     console.log(this.eventProfiles)
    });
  }

  updateStatus() {
    this.projectService.getProjectSelectedEntitiesDetails(this.project, this.p).then(response => {
      if (response?.result) {
        this.project = response.result;
      }
    });
  }


  selectAll(items, key) {
    if (key == 'nature') {
      this.natureSelectedList = items;
    }
    if (key == 'dr') {
      this.drsSelectedList = items;
    }
    if (key == 'groupe') {
      this.groupeSelectedList = items;
    }
    if (key == 'plaque') {
      this.plaqueSelectedList = items;
    }
    this.updateStructure();
  }

  updateStructure() {
    this.projectService.findStructure(this.natureSelectedList, this.drsSelectedList, this.groupeSelectedList, this.plaqueSelectedList, this.events).then(res => {
      if (this.natureSelectedList.length == 0) {
        this.naturesList = this.natureOriginList.filter(dr => res.map(r => r['libelle_nature']).indexOf(dr.libelle_nature) > -1);
      }
      if (this.drsSelectedList.length == 0) {
        this.drsList = this.drsOriginList.filter(dr => res.map(r => r['dr']).indexOf(dr.direction_regionale) > -1);
      }
      if (this.groupeSelectedList.length == 0) {
        this.groupeList = this.groupeGlobalList.filter(dr => res.map(r => r['groupe']).indexOf(dr.groupe) > -1);
      }
      if (this.plaqueSelectedList.length == 0) {
        this.plaqueList = this.plaqueGlobalList.filter(dr => res.map(r => r['plaque_ou_pole']).indexOf(dr.plaque_ou_pole) > -1);
      }
      this.project.projectEntities = this.globalProjectEnteties.filter(dr => res.map(r => r['code_entite']).indexOf(dr.code_entite) > -1);
      this.updateStatus();
    });
  }


  filterData() {// Consider moving this as a class member
    this.project.projectEntities = this.pipe.transform(this.globalProjectEnteties, this.term);
    this.updateStatus();
  }


  export() {
    this.projectService.getProjectSelectedEntitiesDetails(this.project, null).then(response => {
      if (response?.result) {
        let entities = response.result.projectEntities;
        let result = entities.map(e => {
          let events = {};
          e.events.forEach(ev => {
            events[ev.event_name] = ev.current_status;
            ev.event_attributes.forEach(a => {
              if (a.type != 'file' && a.type != 'template') {
                events[ev.event_name] += ";" + (ev.current_status == "Validé" ? (a.label + " " + (a.value != null ? a.value : "")) : "");
              } else {
                events[ev.event_name] += ";" + (ev.current_status == "Validé" ? (a.label + " " + (a.filename != null ? a.filename : "")) : "");
              }
            })
          });
          return {
            ...{
              "Direction regionale": e.direction_regionale,
              "Groupe": this.globalGroupPlaqueList.filter(g => e.code_entite == g.code_entite)[0].groupe,
              "RRF": e.code_entite,
              "Raison sociale": e.raison_sociale,
              "Nom usuel": e.nom_usuel_renault,
              "Nature de site": e.libelle_nature,
            }, ...events
          }
        });
        this.projectService.saveAsCsv(result, 'avancement')
      }
    });
  }
}
