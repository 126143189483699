import { Component, OnDestroy, OnInit } from "@angular/core";
import { ProjetService } from "src/app/service/projet.service";
import { UserService } from "../../service/user.service";
import { Subscription } from "rxjs";
import { AuthService } from "src/app/auth/auth.service";
declare var $: any;

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.css"]
})
export class NavigationComponent implements OnInit, OnDestroy {

  public user;
  public disabled: boolean = true;
  public isAdmin = false;
  private subscription = new Subscription();
  public userType: string;
  private selecteProfile : any;
  authDealersDisplayed =false;
  private arcaAccess = false;
  private dealerAccess = false;
  private userContext = [];
  private showvalidated=false;
  authRes: any;
  constructor(private authService: AuthService,private projetservice: ProjetService,
    private userService: UserService) { this.userType = sessionStorage.getItem('userType'); }

  ngOnInit() {
    this.projetservice.project_exists$.subscribe(res => {
      this.disabled = res;
      if (this.userType == 'admin') { this.isAdmin = true }
    });
    this.subscription.add(this.userService.currUserObservable.subscribe(user => {
      this.user = user;
    }));
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  checkUserProfile(){
      const res = sessionStorage.getItem('authRes')
      this.authRes = JSON.parse(res)
      if (this.authRes && this.authRes != 'undefined' && this.authRes != 'null' && this.authRes.hasOwnProperty('id_token') ){
        
        if(this.authRes.arcaAccess && this.authRes.dealerAccess){
          this.arcaAccess=true;
          this.dealerAccess=true
          this.userContext = this.authRes.usersContext;
        }

        if(this.authRes.arcaAccess && this.authRes.dealerAccess == undefined){
          this.arcaAccess=true;
          this.dealerAccess=false;
        }

        if(this.authRes.dealerAccess && this.authRes.arcaAccess == undefined){
          this.dealerAccess=true;
          this.userContext = this.authRes.usersContext;
        }       
      }
  }

  handleCollapse(){
    this.authDealersDisplayed = ! this.authDealersDisplayed;
  }

  profileRadioSelected(){
    console.log(this.selecteProfile);
    this.showvalidated=true
  }

  doAuthArca(){
    this.authRes['authType'] = 'centrale';
    this.authService.checkAccesRights(this.authRes);
    $("#profileChangeModal").modal('hide');
  }

  doAuthDealer(){
    this.authRes['authType'] = 'dealer'
    this.authRes['dealerProfile'] = this.selecteProfile;
    this.authService.checkAccesRights(this.authRes);
    $("#profileChangeModal").modal('hide');
  }
}
