import { Component, OnInit } from "@angular/core";
import { Projets } from "src/app/data/Project-mock";
import { ProjetWkf } from "src/app/models/projetWkf";

@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.css"]
})
export class LandingComponent implements OnInit {
  projets = Projets;
  selectProjet = ProjetWkf;
  constructor() {}

  ngOnInit() {}
}
