import { Injectable } from "@angular/core";
import { Observable, throwError, BehaviorSubject, of } from "rxjs";
import { catchError, map, retry } from "rxjs/operators";
import * as XLSX from 'xlsx';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { profiles, responsableProfiles } from "../data/profiles";
import { ENV } from "../core/env.config";
import { ToastrService } from "ngx-toastr";
import { saveAs } from 'file-saver';
const httpOptionsFile = {
  headers: new HttpHeaders({
    'accept': 'application/json, text/plain, */*',
    'Access-Control-Allow-Origin': '*',
  })
}
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: "root",
})
export class ProjetService {
  baseUri: string = ENV.API_URL;
  headers = new HttpHeaders()
    .set("content-type", "application/json")
    .set("Access-Control-Allow-Origin", "*");

  constructor(private http: HttpClient, private toastr: ToastrService,) { }

  public eventsList: BehaviorSubject<any> = new BehaviorSubject([]);
  public eventspecList: BehaviorSubject<any> = new BehaviorSubject([]);
  public eventTrackList: BehaviorSubject<any> = new BehaviorSubject([]);
  public eventObj: BehaviorSubject<any> = new BehaviorSubject(null);
  public eventsObj$ = this.eventObj.asObservable();

  public modifiedeventObj: BehaviorSubject<any> = new BehaviorSubject(null);
  public modifiedeventObj$ = this.modifiedeventObj.asObservable();

  public eventTrackListNext$ = this.eventTrackList.asObservable();
  public eventsListNext$ = this.eventsList.asObservable();
  public foundProfiles = [];
  public eventspecListNext$ = this.eventspecList.asObservable();

  public modifiedObj: BehaviorSubject<any> = new BehaviorSubject(null);
  public modifiedObj$ = this.modifiedeventObj.asObservable();

  public projectListObj: BehaviorSubject<any> = new BehaviorSubject([]);
  public projectListObj$ = this.projectListObj.asObservable();


  public project_exists: BehaviorSubject<any> = new BehaviorSubject(true);
  public project_exists$ = this.project_exists.asObservable();
  public event: BehaviorSubject<any> = new BehaviorSubject([]);
  public event$ = this.event.asObservable();

  public _test: any;

  set test(value: any) {
    this._test = value
  }

  get test(): any {
    return this._test;
  }
  setEvent(ev) {
    this.event.next(ev);
  }

  setProjectExits(is_project) {
    this.project_exists.next(is_project);
  }

  setProjectObj(projectsArray) {
    this.projectListObj.next(projectsArray);
  }

  setModifiedObj(obj) {
    this.modifiedObj.next(obj);
  }

  setEventObj(newList) {
    this.eventObj.next(newList);
  }
  setUpdateEventspecList(newList) {
    this.eventspecList.next(newList);
  }

  setUpdateEventsList(newList) {
    this.eventsList.next(newList);
  }
  setEventsTracksList(updatedList) {
    this.eventTrackList.next(updatedList);
  }

  public getProfiles(searchTerm) {
    var filteredArray = [];
    filteredArray = profiles.filter(function (email) {
      return email.includes(searchTerm);
    });
    return of(filteredArray);
  }

  public getProfilesResponsable(searchTerm) {
    var filteredArray = [];
    filteredArray = responsableProfiles.filter(function (email) {
      return email.includes(searchTerm);
    });
    return of(filteredArray);
  }

  getProjets(idRrf): Promise<any> {
    let url = `${this.baseUri}/projects`;
    return idRrf ? this.http.get(url, { headers: this.headers, params: { 'id_rrf': idRrf } }).toPromise() :
      this.http.get(url, { headers: this.headers }).pipe(catchError(this.handleError)).toPromise();
  }

  getUserProjets(idRrf, userUid, userType): Promise<any> {
    let url = `${this.baseUri}/projects`;
    return this.http.get(url, {
      headers: this.headers,
      params: {
        'id_rrf': idRrf,
        'uid': userUid,
        'type': userType
      }
    }).pipe(catchError(this.handleError)).toPromise();
  }

  //select project projectselect  
  getProjet(projet) {
    let url = `${this.baseUri}/projectselect`;
    return this.http.post(url, projet).pipe(catchError(this.handleError));
  }

  getwkfevent(data) {
    let url = `${this.baseUri}/getwkfevent`;
    return this.http.post(url, data).pipe(catchError(this.handleError));
  }

  getWkf(wkf) {
    let url = `${this.baseUri}/getwkf`;
    return this.http.post(url, wkf).pipe(catchError(this.handleError));
  }

  getDetailsFromIpn(ipn) {
    let url = `${this.baseUri}/ipn`;
    return this.http.post(url, { ipn: ipn }).pipe(catchError(this.handleError));

  }

  // Get event details
  getEvent(event) {
    let url = `${this.baseUri}/getevent`;
    return this.http
      .post(url, event, { headers: this.headers })
      .pipe(catchError(this.handleError));
  }
  //upodate the status of the event 
  updateEventStatus(event) {
    let url = `${this.baseUri}/update_event_status`;
    return this.http.put(url, event).pipe(catchError(this.handleError));

  }

  getPercentage(projectName) {
    let url = `${this.baseUri}/status_percentages/${projectName}`;
    return this.http.get(url, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  getValidatedEventsPercentage(projectName) {
    let url = `${this.baseUri}/validated_events_count/${projectName}`;
    return this.http.get(url, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  getDrValidatedEvents(projectName) {
    let url = `${this.baseUri}/dr_percentage/${projectName}`;
    return this.http.get(url, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  getDrTotalValidatedEvents(projectName) {
    let url = `${this.baseUri}/dr_total_validated_events/${projectName}`;
    return this.http.get(url, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  getFiltersList(projectObj) {
    let url = `${this.baseUri}/filter_events`;
    return this.http.post(url, projectObj).pipe(catchError(this.handleError));
  }

  getFiltersDrs(obj) {
    let url = `${this.baseUri}/filter_drs`;
    return this.http.post(url, obj).pipe(catchError(this.handleError));
  }

  getFiltersRrfs(obj) {
    let url = `${this.baseUri}/filter_rrfs`;
    return this.http.post(url, obj).pipe(catchError(this.handleError));
  }

  getFilteredData(obj) {
    let url = `${this.baseUri}/filtered_data`;
    return this.http.post(url, obj).pipe(catchError(this.handleError));
  }

  getSingleDrEventsState(data, project) {
    let url = `${this.baseUri}/single_dr_events_state/${project}`;
    return this.http.post(url, data).pipe(catchError(this.handleError));

  }

  getMonthlyEventCount(projectName) {
    let url = `${this.baseUri}/validated_events_per_month/${projectName}`;
    return this.http.get(url, { headers: this.headers }).pipe(catchError(this.handleError));
  }

  //updatewkf
  updateWkf(data) {
    let url = `${this.baseUri}/updatewkf`;
    return this.http
      .post(url, data, { headers: this.headers })
      .pipe(catchError(this.handleError));
  }

  updateWorkflowAfterEmails(objectsList) {
    let url = `${this.baseUri}/updatewkfemails`;
    return this.http.post(url, objectsList, { headers: this.headers })
      .pipe(catchError(this.handleError));
  }

  // -------------------------------- new methods after refacto keep this ones -----------------------------------------


  getProjectEntitiesDetails(projectId, userUid, userType, idRrf, page): Promise<any> {
    return this.http.get(`${this.baseUri}/project/entities`, {
      headers: this.headers,
      params: {
        'projectId': projectId,
        'userUid': userUid,
        'userType': userType,
        'idRrf': idRrf,
        'page': page,
      }
    }).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  getProjectEntitiesDetailsRSI(projectId, userUid, userType, idRrf, page): Promise<any> {
    return this.http.get(`${this.baseUri}/project/entitiesRSI`, {
      headers: this.headers,
      params: {
        'projectId': projectId,
        'userUid': userUid,
        'userType': userType,
        'idRrf': idRrf,
        'page': page,
      }
    }).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  getProjectEntitiesByAvancement(events): Promise<any> {
    return this.http.post(`${this.baseUri}/project/selectedEntitiesByAvancement`, events).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  getProjectSelectedEntitiesDetails(project, page): Promise<any> {
    return this.http.post(`${this.baseUri}/project/selectedEntities`, project, {
      headers: this.headers,
      params: {
        'page': page,
      }
    }).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  getProjectEntityEvents(projectId: any, codeEntite: any): Promise<any> {
    return this.http.get(`${this.baseUri}/project/entities/events/${projectId}/${codeEntite}`, { headers: this.headers }).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  createEvent(data): Promise<any> {
    return this.http.post(`${this.baseUri}/project/event/base`, data).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  // Get all events
  getBaseEvents(): Promise<any> {
    return this.http.get(`${this.baseUri}/events/base`, { headers: this.headers }).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  /**
   * TODO refacto this one
   * @param projectName
   */
  getSpecEvents(projectName: any): Promise<any> {
    return this.http.get(`${this.baseUri}/events/spec`, {
      headers: this.headers,
      params: {
        projectName: projectName
      }
    }).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  findProjectWithSameName(projectName: any): Promise<any> {
    return this.http.get(`${this.baseUri}` + '/project/name', {
      headers: this.headers,
      params: {
        projectName: projectName
      }
    }).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  findEventProjectWithSameName(eventName: any): Promise<any> {
    return this.http.get(`${this.baseUri}` + '/events/base/name', {
      headers: this.headers,
      params: {
        eventName: eventName
      }
    }).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }



  upload(file: any, key: string) {

    let formData = new FormData();
    formData.append("file", file);
    return this.http.post(`${this.baseUri}/entity/upload/${key}`, formData, httpOptionsFile).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    );
  }

  uploadArca(file: any) {

    let formData = new FormData();
    formData.append("file", file);
    return this.http.post(`${this.baseUri}/arca/uploadArca`, formData, httpOptionsFile).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    );
  }


  createSpecificEvent(event: any): Promise<any> {
    return this.http.post(`${this.baseUri}/events/spec`, event).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  createBaseEvent(event: any): Promise<any> {
    return this.http.post(`${this.baseUri}/events/base`, event).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  deleteBaseEvent(event: any): Promise<any> {
    return this.http.post(`${this.baseUri}/events/base/delete`, event).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  deleteFile(path: any): Promise<any> {
    return this.http.get(`${this.baseUri}/file/delete`, {
      params: {
        path: path
      }
    }).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  updateSpecificEvent(event: any): Promise<any> {
    return this.http.put(`${this.baseUri}/events/spec`, event).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  updateBaseEvent(event: any): Promise<any> {
    return this.http.put(`${this.baseUri}/events/base`, event).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  loadEventsAttributes(): Promise<any> {
    return this.http.get('assets/data/events-attributes.json').pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  loadEntitiesDetails(): Promise<any> {
    return this.http.get(`${this.baseUri}/entity/reseau/perimetre`).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  loadCodeNaturesReseau(): Promise<any> {
    return this.http.get('assets/data/perim_reseau.json').pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  createProject(project: any) {
    return this.http.post(`${this.baseUri}/project`, project, { headers: this.headers }).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  getByGroupeOrPlaque(term: any, idRrf: any): Promise<any> {
    return this.http.get(`${this.baseUri}/entity/groupeorplaque/${term}`, {
      headers: this.headers,
      params: {
        idRrf: idRrf,
      }
    }
    ).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  findStructure(natureList: any, drList: any, groupeList: any, plaqueList: any, events: any): Promise<any> {
    return this.http.post(`${this.baseUri}/entity/findStructure`, {
      nature: natureList,
      dr: drList,
      groupe: groupeList,
      plaque: plaqueList,
      events: events
    }).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  findGroupPlaqueForDr(dr: any): Promise<any> {
    return this.http.get(`${this.baseUri}/entity/findGroupAndPlaqueForDr/${dr}`, { headers: this.headers }).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  updateDmsInEntities(): Promise<any> {
    return this.http.get(`${this.baseUri}/vectury/entities/dms/update`, { headers: this.headers }).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  updateDmsInPartners(): Promise<any> {
    return this.http.get(`${this.baseUri}/partenaire/dms/update`, { headers: this.headers }).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  //affaires add or delete end points
  getAllAffaires(): Promise<any> {
    return this.http.get(`${this.baseUri}/entity/rrf/all`, { headers: this.headers }).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  getAffairesList(term: string): Promise<any> {
    return this.http.get(`${this.baseUri}/entity/rrf/addordelete/${term}`, { headers: this.headers }).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }
  addRrf(rrrfObj): Promise<any> {
    return this.http.post(`${this.baseUri}/entity/rrf/new`, rrrfObj, { headers: this.headers }).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }
  deleteRrf(rrrfObj): Promise<any> {
    return this.http.post(`${this.baseUri}/entity/rrf/delete`, rrrfObj, { headers: this.headers }).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  addAllRrf(rrrfObj): Promise<any> {
    return this.http.post(`${this.baseUri}/entity/rrf/all/new`, rrrfObj, { headers: this.headers }).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }
  deleteAllRrf(rrrfObj): Promise<any> {
    return this.http.post(`${this.baseUri}/entity/rrf/all/delete`, rrrfObj, { headers: this.headers }).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }


  getProjectEventsByProjectIdAndUserIdRrf(projectId: any, userIdRrf: any): Promise<any> {
    return this.http.get(`${this.baseUri}/project`, {
      headers: this.headers,
      params: {
        'projectId': projectId,
        'userIdRrf': userIdRrf
      }
    }).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  getProjectProfiles(projectId: any, idRrf: any): Promise<any> {
    return this.http.get(`${this.baseUri}/profiles`, {
      params: {
        'project_id': projectId,
        'id_rrf': idRrf
      },
      headers: this.headers
    }).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  getProjectProfilesProject(projectId: any, idRrf: any): Promise<any> {
    return this.http.get(`${this.baseUri}/profilesProject`, {
      params: {
        'project_id': projectId,
        'id_rrf': idRrf
      },
      headers: this.headers
    }).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  getProjectProfilesProjectclean(projectId: any): Promise<any> {
    return this.http.get(`${this.baseUri}/profilescleanup`, {
      params: {
        'project_id': projectId
      },
      headers: this.headers
    }).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }


  getProjectProfilesResp(projectId: any,profileId, idRrf: any): Promise<any> {
    return this.http.get(`${this.baseUri}/profilesResp`, {
      params: {
        'project_id': projectId,
        'profile_id': profileId,
        'id_rrf': idRrf
      },
      headers: this.headers
    }).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  getProjectProfilesRespOne(projectId: any,profileId, idRrf: any): Promise<any> {
    return this.http.get(`${this.baseUri}/profilesRespOne`, {
      params: {
        'project_id': projectId,
        'profile_id': profileId,
        'id_rrf': idRrf
      },
      headers: this.headers
    }).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  setProjectProfilesResponsables(projectId: any, profiles: any): Promise<any> {
    return this.http.put(`${this.baseUri}/profiles/responsible/${projectId}`, profiles, {
      headers: this.headers
    }).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  setProjectProfilesResponsablesClean(projectId: any, profiles: any): Promise<any> {
    return this.http.put(`${this.baseUri}/profiles/responsible/clean/${projectId}`, profiles, {
      headers: this.headers
    }).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  getPersonDetails(ipn: any): Promise<any> {
    return this.http.get(`${this.baseUri}/vectury/person/details/${ipn}`).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  searchPerson(codesRrf:any[],name): Promise<any> {
    return this.http.get(`${this.baseUri}/vectury/persons/search`,{
      headers: this.headers,
      params: {
        'name': name,
        'rrf': codesRrf.join(',')
      }}).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  searchPersonUid(resps:any[]): Promise<any> {
    return this.http.post(`${this.baseUri}/vectury/persons/searchuid`,resps,httpOptionsFile)
      .pipe(
        retry(0),
        catchError((errorResponse) => this.handleError(errorResponse))
      ).toPromise();
  }

  searchEditeursDms(rrfs): Promise<any> {
    return this.http.get(`${this.baseUri}/vectury/persons/dms/search`, {
      headers: this.headers,
      params: {
        rrfs: rrfs
      }
    }).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  searchValideurCentrale(codesNatures, fonctions): Promise<any> {
    return this.http.get(`${this.baseUri}/arca/validator`, {
      headers: this.headers,
      params: {
        codesNatures: codesNatures,
        fonctions: fonctions
      }
    }).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  getAllFonctions(): Promise<any> {
    return this.http.get(`${this.baseUri}/arca/fonctions`).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  getAllUsers(): Promise<any> {
    return this.http.get(`${this.baseUri}/arca/users`).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  searchWdyAdaMembers(): Promise<any> {
    return this.http.get(`${this.baseUri}/arca/roles/members`, {
      headers: this.headers,
    }).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  updateProjectEventStatus(event): Promise<any> {
    return this.http.put(`${this.baseUri}/project/entities/events/${event.project_event_id}`, event, {
      headers: this.headers
    }).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  uploadFile(file): Promise<any> {
    return this.http.post(`${this.baseUri}/project/entities/upload`, file).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  downloadFile(filepath, filename) {
    window.open(`${this.baseUri}/project/entities/download?filepath=` + filepath + "&filename=" + filename, "_self");
  }

  getProjectForEdition(projectId: any): Promise<any> {
    return this.http.get(`${this.baseUri}/project/${projectId}`).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  deleteProject(projectId: any): Promise<any> {
    return this.http.delete(`${this.baseUri}/project/${projectId}`).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  updateProjectStatus(projectStatus,projectId: any): Promise<any> {
    return this.http.get(`${this.baseUri}/updateProject/${projectId}/${projectStatus}`).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  getEntityDetails(idRrf): Promise<any> {
    return this.http.get(`${this.baseUri}/entity/${idRrf}`, { headers: this.headers }).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  updateProject(projectId: any, project: any) {
    return this.http.put(`${this.baseUri}/project/${projectId}`, project, { headers: this.headers }).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  getProjectEventsByIds(query) {
    return this.http.get(`${this.baseUri}/project/project_event_id/${query}`, { headers: this.headers }).pipe(
      retry(0),
      catchError((errorResponse) => this.handleError(errorResponse))
    ).toPromise();
  }

  handleError(errorResponse: HttpErrorResponse) {
    console.log('errorResponse :', errorResponse)
    this.toastr.error(errorResponse.error.message, "Erreur");
    return of(false);
  }

  saveAsTextFile(dataList) {

    var data = new Blob([dataList], { type: 'text/plain' });

    let url = window.URL.createObjectURL(data);

    let a = document.createElement('a');
    document.body.appendChild(a);

    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = 'ipn.txt';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();

  }

  saveAsCsv(data: any, title: any) {
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(';'));
    csv.unshift(header.join(';'));
    let csvArray = csv.join('\r\n');
    var BOM = "\uFEFF";
    csvArray = BOM + csvArray;
    var blob = new Blob([csvArray], { type: 'application/csv;charset=utf-8' })
    saveAs(blob, title + ".csv");
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
}