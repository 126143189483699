import { BrowserModule } from "@angular/platform-browser";
import { LOCALE_ID, NgModule } from "@angular/core";
import { DndModule } from "ngx-drag-drop";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { LandingComponent } from "./components/landing/landing.component";
import { NavigationComponent } from "./components/navigation/navigation.component";
import { TbWkfComponent } from "./components/tb-wkf/tb-wkf.component";
import { ProjetsComponent } from "./components/projets/projets.component";
import { TabDetailsComponent } from "./components/tab-details/tab-details.component";
import { WkfEventComponent } from "./components/wkf-event/wkf-event.component";
import { WkfChartComponent } from "./components/wkf-chart/wkf-chart.component";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { NgApexchartsModule } from "ng-apexcharts";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { NgxPaginationModule } from "ngx-pagination";
import { EditEventComponent } from "./components/edit-event/edit-event.component";
import { ListEventComponent } from "./components/list-event/list-event.component";
import { EditWkfComponent } from "./components/edit-wkf/edit-wkf.component";
import { ListWkfComponent } from "./components/list-wkf/list-wkf.component";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { EditProjectComponent } from "./components/edit-project/edit-project.component";
import { NgWizardModule, NgWizardConfig, THEME } from "ng-wizard";
import { EventParamaterComponent } from './components/event-parameter/event-parameter.component';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ExcelHandlerComponent } from './components/excel-handler/excel-handler.component';
import { WithParentPipe } from '../app/components/tab-details/index.pipe';
import { LoaderComponent } from './components/loader/loader.component';
import { registerLocaleData } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import localeFr from '@angular/common/locales/fr';
import { SortDirective } from './directive/sort.directive';
import { MatDialogModule } from "@angular/material/dialog";
import { MatTooltipModule } from "@angular/material/tooltip";
import { NgSelectModule } from "@ng-select/ng-select";
import { CallbackComponent } from './auth/callback/callback.component';
import { ProjectEventsProfilesComponent } from './components/project-events-profiles/project-events-profiles.component';
import { MatGridListModule } from "@angular/material/grid-list";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatDividerModule } from "@angular/material/divider";
import {MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS} from '@angular/material/radio'
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SpinnerComponent } from './spinner/spinner.component';
import { MatTabsModule } from '@angular/material/tabs';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ReferentielReseauComponent } from './components/referentiel-reseau/referentiel-reseau.component';
import { UploadComponent } from './components/upload/upload.component';
import { FileUploadModule } from "primeng/fileupload";
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { LoaderService } from "./spinner/loader.service";
import { LoaderInterceptor } from "./spinner/loader.interceptor";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AuthInterceptor } from './auth/auth.interceptor';
import {MatChipsModule} from '@angular/material/chips';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

registerLocaleData(localeFr, 'fr');
const ngWizardConfig: NgWizardConfig = {
  theme: THEME.circles,
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LandingComponent,
    NavigationComponent,
    TbWkfComponent,
    ProjetsComponent,
    TabDetailsComponent,
    WkfEventComponent,
    WkfChartComponent,
    EditEventComponent,
    ListEventComponent,
    EditWkfComponent,
    ListWkfComponent,
    EditProjectComponent,
    EventParamaterComponent,
    ExcelHandlerComponent,
    WithParentPipe,
    SortDirective,
    LoaderComponent,
    CallbackComponent,
    ProjectEventsProfilesComponent,
    SpinnerComponent,
    ReferentielReseauComponent,
    UploadComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    NgApexchartsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgMultiSelectDropDownModule.forRoot(),
    Ng2SearchPipeModule,
    NgxPaginationModule,
    DndModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgWizardModule.forRoot(ngWizardConfig),
    MatDialogModule,
    MatTooltipModule,
    NgSelectModule,
    MatGridListModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    TypeaheadModule.forRoot(),
    MatTabsModule,
    MatRadioModule,
    ModalModule.forRoot(),
    FileUploadModule,
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    MatCheckboxModule,
    NgbTooltipModule
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'fr' }, LoaderService, { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },{provide: MAT_RADIO_DEFAULT_OPTIONS,useValue: { color: 'accent' },
}],
  bootstrap: [AppComponent],
})
export class AppModule { }
