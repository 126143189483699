const _isLocalhost = window.location.port.indexOf('4200') > -1;
const _isDev = window.location.origin.indexOf('https://app-wdy.gke.dev.gcp.renault.com') > -1;
const _isQa = window.location.origin.indexOf('https://app-wdy.gke.int.gcp.renault.com') > -1;
const _isProd = window.location.origin.indexOf('https://wdy.dcs2.renault.com') > -1;

const getHostApi = () => {
    if (_isLocalhost) { return 'http://localhost:8080/api/v1'; }
    else {
        return '/api/v1';
    }
};

const getApiKey = () => {
    if (_isLocalhost) { return 'sLtjQFYywKoiq8qJhPUOn925Ztr3ODus'; }
    if (_isDev) { return 'API_KEY_DEV'; }
    if (_isQa) { return 'API_KEY_QA'; }
    if (_isProd) { return '46fecd61-c81b-405c-9b8a-1beebb60f2a0'; }
};

const getUrlAuth = () => {
    if (_isLocalhost) {
        return 'http://localhost:8080/api/v1/auth';
    }
    else {
        return '/api/v1/auth';
    }
};

const getClientId = () => {
    return _isProd ? 'irn-72239_ope_code_j0up36ckm6vx' : 'irn-72239_uat_code_yfk66ddl30hp'
};


const getAuthDomain = () => {
    return _isProd ? 'https://sso.renault.com/oauth2/aus133y6mks4ptDss417/v1/authorize':'https://sso-uat.renault.com/oauth2/austwoeshbCkPJyw1416/v1/authorize'
};

const getRedirectUrl = () => {
    if (_isLocalhost) { return 'http://localhost:4200/auth_callback'; }
    if (_isDev) { return 'https://app-wdy.gke.dev.gcp.renault.com/auth_callback'; }
    if (_isQa) { return 'https://app-wdy.gke.int.gcp.renault.com/auth_callback'; }
    if (_isProd) { return 'https://wdy.dcs2.renault.com/auth_callback'; }
};


const getScope = () => {
    return 'openid offline_access alliance_profile';
};

const getState = () => {
    return '3gTy3bZJzgba0MYQcYTbQKsuKIfyCA7lHmRdbeFk';
};


const getReponseType = () => {
    return 'code';
};

export const ENV = {
    API_URL: getHostApi(),
    API_KEY: getApiKey(),
    API_SRC_URL: getHostApi(),
    AUTH_URL: getUrlAuth(),
    AUTH_DOMAIN: getAuthDomain(),
    RESPONSE_TYPE: getReponseType(),
    STATE: getState(),
    REDIRECT_URI: getRedirectUrl(),
    AUTH_SCOPE: getScope(),
    BASE_API_URL_PORTAILSRC: getHostApi(),
    CLIENT_ID : getClientId()
}

