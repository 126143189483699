import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ProjetService } from "../../service/projet.service";
import { ToastrService } from "ngx-toastr";
import { FormControl } from '@angular/forms';
import { Observable, Observer, of } from 'rxjs';
import { startWith, map, switchMap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
export interface Person {
  fullName: any;
  uid: string;
  firstName: string;
  lastName: string;
  mail: string;
  isSelected: boolean;
}

@Component({
  selector: 'app-project-events-profiles',
  templateUrl: './project-events-profiles.component.html',
  styleUrls: ['./project-events-profiles.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ProjectEventsProfilesComponent implements OnInit {
  public dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    searchPlaceholderText: 'Recherche...',
    noDataAvailablePlaceholderText: 'pas de affaire disponibles',
    idField: 'code_entite',
    textField: 'nom_usuel_renault',
    allowSearchFilter: false,
  };
  public dropdownSettingsProfil: IDropdownSettings = {
    singleSelection: false,
    searchPlaceholderText: 'Recherche...',
    noDataAvailablePlaceholderText: 'pas de profil disponibles',
    idField: 'profile_id',
    textField: 'name',
    allowSearchFilter: false,
  };
  private projectId: any;
  private userIdRrf: any;
  projectEvents: any[] = [];
  projectProfiles: any[] = [];
  isSaveAuthorised = false;
  searchedIpn: any;
  control = new FormControl('');
  personSearchResults: Person[]=[];
  filteredPersons: Observable<Person[]>;
  searchValue?: string;
  selectedPerson: any;
  affectedPersonProfiles: any = []
  isSubmitted: boolean = false;
  personsId: any = [];
  isPushed: boolean = false;
  tempProfiles: any = [];
  profils: any[];
  projectName: any;
  projectEntity: any;
  affaires: any;
  projectEntities: any;
  personOriginResult: any;
  selectedPersons: any = [];
  selectedPersonsGrouped: any = [];

  constructor(private _location: Location, private router: Router, private projectService: ProjetService, private toastr: ToastrService, private ref: ChangeDetectorRef) {
    const state = this.router.getCurrentNavigation().extras.state;
    if (state?.projectId) {
      this.projectName = state.projectName;
      this.projectId = state.projectId;
      this.userIdRrf = state.idRrf;
      this.getProfiles();
    } else {
      this.router.navigateByUrl('project/home');
    }
  }
  backClicked() {
    this._location.back();
  }

  ngOnInit(): void {
  }

  private _filter(value: any): Person[] {
    const filterValue = value.toLowerCase();
    return this.personSearchResults.filter(state => state.firstName?.toLowerCase().includes(filterValue));
  }


  onSelectRow(item:any): void {
    if(item.mail === undefined || item.mail === null || item.mail === '') {
      this.toastr.error("Cet utilisateur n'a pas de mail. Veuillez renseigner un mail pour l'ajouter.");
      item.isSelected = false;
      return;
    }
    if(item.mail !== undefined && item.mail !== null && (item.mail)?.includes("reseau.renault.fr")) {
      this.toastr.error("L'adresse email de cet utilisateur est invalide !");
      item.isSelected = false;
      return;
    }
    else{
      this.affaires = item.entity;
      this.projectProfiles.forEach(p => { p.isChecked = false; });
      this.selectedPerson = item;
      this.handleCheckedPofile(this.selectedPerson);
      this.personSearchResults.forEach(p => {if(p.mail !== item.mail) p.isSelected = false;});
      item.isSelected = !item.isSelected;
    }
  }
  
  mailIsValid(mail: any): boolean {
    if(mail === undefined || mail === null || mail === '') {
      return true;
    }
    if(mail !== undefined && mail !== null && (mail)?.includes("reseau.renault.fr")) {
      return true;
    }
      return false;

  }

  handleCheckedPofile(selectedPerson) {
    if (selectedPerson) {
      this.projectProfiles.forEach(p => {
        const resp = p.responsables.find(r => r.uid === selectedPerson.uid);
        p.isChecked = resp !== undefined;
      });
    }
  }

  getEvents() {
    this.projectService.getProjectEventsByProjectIdAndUserIdRrf(this.projectId, this.userIdRrf).then(response => {
      this.projectEvents = response || [];
    });
  }

  getProfiles() {
    this.projectService.getProjectEntitiesDetailsRSI(this.projectId, null, "rsi", this.userIdRrf, 1).then(async response => {
      this.projectEntities = response.result.projectEntities;
    this.projectService.getProjectProfilesProject(this.projectId, this.projectEntities.map(e => e.code_entite)).then(response => {
      this.projectProfiles = response?.result ? response.result : [];
      this.projectProfiles.forEach(async profil => {
       const respo = await this.projectService.getProjectProfilesResp(this.projectId, profil.profile_id,this.projectEntities.map(e => e.code_entite))
       profil.responsables = respo.result
       if (profil.responsables) {
          profil.responsables = profil.responsables.filter(r => r.responsables).map(r => r.responsables).flat(1);
          if (!profil.responsables) {
            profil.responsables = [];
          }
          profil.responsables = profil.responsables.reduce((accumulator, current) => {
            if (!accumulator.find((item) => item.fullName === current.fullName)) {
              accumulator.push(current);
            }
            return accumulator;
          }, []);
        }
      });
      this.profils = this.projectProfiles.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.profile_id === value.profile_id
        ))
      )
    });
    this.filteredPersons = this.control.valueChanges.pipe(
      startWith(''),
      map(Person => (Person ? this._filter(Person) : this.personSearchResults.slice())),
    );
    });
  }

  checkIfCanSave() {
    const notFoundResponsible = this.profils.filter(p => !p.responsables || p.responsables.length === 0);
    this.isSaveAuthorised = notFoundResponsible.length === 0;
  }

  extractResp(){
    const res = []
    this.profils.forEach(p => {
     const resps = p.responsables as any[];

     if(resps != undefined && resps != null){
        resps.forEach(r => {
          const prenom = r.firstName;
          const nom = r.lastName;          
          const entities = r.entitySelected as any[];

          if(entities != undefined && entities.length != undefined){
            entities.forEach(e => {
              var val = {} as any;
              val['nom'] = nom;
              val['prenom'] = prenom;
              val['code_entite'] = e.code_entite;
              res.push(val);
            });
          }
        });
     }
    });
    return res
  }

  async save() {
    let result = [];
    this.projectEntities.forEach(entity => {
      let projectProfiles = [];
      this.projectProfiles.forEach(p => {
        let responsables = Object.assign([], this.profils.find(f => f.profile_id == p.profile_id).responsables);
        responsables = responsables.filter(res => res.entitySelected && res.entitySelected.find(e => e.code_entite == entity.code_entite));
        let resNew = [];
        responsables.forEach(r => {
          let rN = {} as any;
          rN.uidEntite = r.uidEntite; 
          rN.lastName = r.lastName;
          rN.firstName = r.firstName;
          rN.fullName = r.fullName;
          rN.entitySelected = r.entitySelected.map(e => {var val = {} as any; val.code_entite = e.code_entite; val.nom_usuel_renault = e.nom_usuel_renault; return val;});
          rN.mail = r.mail;
          rN.uid = r.uidEntite.find(po => po.code_entite == entity.code_entite).uid;
          resNew.push(rN);
        })
        projectProfiles.push({ profile_id: p.profile_id, responsables: resNew });
      })
      result.push({ code_entite: entity.code_entite, profils: projectProfiles });
    });
    console.log('result : ', result)

    this.projectService.setProjectProfilesResponsables(this.projectId, result).then( response => {
      this.toastr.success('Les profiles ont bien étés mis à jour.');
    });
  }

  async cleanUp(){
  const projectId_to_clean = 'ba094717-4a41-4231-b91a-4056317a6310'
  const projectProfs = await this.projectService.getProjectProfilesProjectclean(projectId_to_clean) as any;
  var respVals = projectProfs.result;
  for(let respVal of respVals){
    const respo = await this.projectService.getProjectProfilesRespOne(projectId_to_clean, respVal.profile_id, respVal.id_rrf)    
    var responsables= respo.result[0].responsables;
    var res = [];
    var result = [];
    let projectProfiles = [];
    if(responsables != undefined && responsables != null){
      for( let r of responsables){        
        if(r.entitySelected != undefined && r.entitySelected.length != undefined){
          
          var val = {} as any;
          val['email'] = r.mail;
          val['firstName'] = r.firstName;
          val['lastName'] = r.lastName;
          val['code_entite'] = r.entitySelected.map(e => e.code_entite);
          res.push(val);
        }
      };

      var resUid = {} as any;
      if(res.length > 0){
        resUid = await this.projectService.searchPersonUid(res);
      }
   
      var resArr = []
      res.forEach(val => { 
        val.code_entite.forEach(entite => {
        var elt = {};
        elt['email'] = val.email;
        elt['fullName'] = val.firstName + " " + val.lastName;
        elt['code_entite'] =  entite;
        resArr.push(elt);
        });
      })
      console.log('resArr : ',resArr);
      if(resUid.result != undefined && resUid.result.length > 0){
        const respsUid = resUid.result as any[];
        let resNew = [];
        
        const foundRes = []
        const notFoundRes = []

        for(let val of resArr){
          var found = false;
          for(let val2 of respsUid){
            if(val.email === val2.mail && val.code_entite === val2.code_entite){
              foundRes.push(val);
              found = true;
            }
            if(val.email !== val2.mail && (val.fullName)?.toLowerCase() === (val2.fullName)?.toLowerCase() && val.code_entite === val2.code_entite){
              foundRes.push(val);
              found = true;
            }
          };

          if(!found){
            notFoundRes.push(val);
            console.log('notFoundRes : ',notFoundRes);
            for(let r of responsables) {
              if(r.mail == val.email){
                var entiteTemp = r.entitySelected.find(e => e.code_entite == val.code_entite);
                if(entiteTemp!= undefined){
                  r.entitySelected.splice(r.entitySelected.indexOf(entiteTemp),1);
                }
              }
            };            
          }
        };

        if(foundRes.length > 0){
          for( let r of responsables){
            var findRrf = r.entitySelected.find(e => e.code_entite == respVal.id_rrf);
            if(findRrf!= undefined){
              console.log('r : ',r);
              console.log('respsUid : ',respsUid);
              let rN = {} as any;
              rN.uidEntite = this.filtertTab(respsUid,r.entitySelected,r.fullName,r.mail).map(val => this.pick(val));
              rN.lastName = r.lastName;
              rN.firstName = r.firstName;
              rN.fullName = r.fullName;
              rN.entitySelected = r.entitySelected.map(e => {var val = {} as any; val.code_entite = e.code_entite; val.nom_usuel_renault = e.nom_usuel_renault; return val;});
              rN.mail = r.mail;
              console.log('rN : ',rN);
              console.log('comapre : ' + r.fullName.toLowerCase() + ' ' + r.mail  + ' ' + respVal.id_rrf)
              rN.uid = respsUid.find(po => (po.fullName.toLowerCase() === r.fullName.toLowerCase() || po.mail === r.mail ) && po.code_entite === respVal.id_rrf).uid;
              if( rN.uidEntite === undefined || rN.uidEntite === null){
                console.log('rN.uidEntite null for: ',rN);
              }
              else if(rN.uidEntite.length > 0){
                resNew.push(rN);
              }
              
            }
          }
        }
        if(resNew.length > 0){
        projectProfiles.push({ profile_id: respVal.profile_id, responsables: resNew });
        }
        if(projectProfiles.length > 0){
        result.push({ code_entite: respVal.id_rrf, profils: projectProfiles });
        console.log('result : ',result);
        this.projectService.setProjectProfilesResponsablesClean(projectId_to_clean, result).then( response => {
          console.log('Les profiles ont bien étés mis à jour pour affaire : ',respVal.id_rrf);
          this.toastr.success('Les profiles ont bien étés mis à jour pour affaire : '+respVal.id_rrf);
        });
      }
    
    }
    }
   
  }
  }

  getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }

  pick = ({uid,code_entite}) => ({uid,code_entite})

  filtertTab(uidsEntite:any[], selectedEntity:any[],fullName:string,mail:string) {
    return  uidsEntite.filter(u => selectedEntity.find( e => e.code_entite === u.code_entite && (u.fullName.toLowerCase() === fullName.toLowerCase() || u.mail === mail)));     
  }

  searchIpn(profil: any) {
    this.projectService.getPersonDetails(profil.searchedIpn).then(response => {
      if (response?.result) {
        const searchedUserEntity = response.result.entityIdentifiers.find(e => parseInt(e.id) == parseInt(this.userIdRrf));
        if (searchedUserEntity) {
          profil.responsable = response.result;
          this.checkIfCanSave();
        }
        else {
          this.toastr.warning('Cette personne ne fait pas partie de votre entité');
        }
      }
    });
  }

  async searchPerson(name:any) {
    this.personSearchResults = [];
    this.personOriginResult = [];
    var entities = this.projectEntities.map(elt => elt.code_entite);
    let res = await this.projectService.searchPerson(entities,name);
    let resPers = res.result  as any[];
    for(let elt of resPers){
      var pers = resPers.filter(e => e.mail == elt.mail);
      var entityMap = pers.map(e => e.code_entite);
      var entity = this.projectEntities.filter(e => entityMap.includes(e.code_entite));
      var entityUid = pers.map(e => {var val = {}; val['uid'] = e.uid; val['code_entite'] = e.code_entite; return val; });
      var personCpy = elt;
      personCpy['uidEntite'] = entityUid;
      personCpy['entity'] = entity;
      personCpy['isSelected '] = false;      
      this.personSearchResults.push(personCpy);
    }
    
    this.personSearchResults = this.getUniqueListBy(this.personSearchResults, 'mail') as any[];
    this.personOriginResult = this.personSearchResults;
    this.personSearchResults.sort(function(a, b) {
      return a.fullName.localeCompare(b.fullName)
  });
    console.log('personSearchResults : ',this.personSearchResults)
  }


  setProfileResponsable(selectedPerson) {
    selectedPerson.profilSelected.forEach(p => {
      let profile = this.profils.filter(po => po.profile_id == p.profile_id)[0];
      profile.responsables = profile.responsables.filter(r => r.uid != selectedPerson.uid);
      profile.responsables.push({ ...selectedPerson });
    });
    selectedPerson.profilSelected = null;
    selectedPerson.entitySelected = null;
    this.checkIfCanSave();
  }


  getTitle(person) {
    if (person.entitySelected) {
      var toolTipVals = person.entitySelected.map(e => e.nom_usuel_renault);
      return toolTipVals.join()
    } else {
      return null;
    }
  }


  onChange() {
    this.selectedPerson = "";
    this.searchPerson(this.searchValue)
    this.ref.detectChanges();
  }

  removeProfile(profile) {
    profile.isChecked = false;
    profile.responsables = [];
    this.checkIfCanSave();
  }

  removePerson(uid, profile) {
    const index = profile.responsables.findIndex(ap => ap.uid === uid);
    if (index !== -1) {
      profile.responsables.splice(index, 1);
    }
    if (this.selectedPerson && this.selectedPerson.uid === uid) {
      profile.isChecked = false;
    }
    this.checkIfCanSave();
  }

}
