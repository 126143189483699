import { ProjetWkf } from "../models/projetWkf";

export const Projets: ProjetWkf[] = [
  { id: 1, nom: "Projet 1", population: "R1" },
  { id: 2, nom: "Projet 2", population: "R1" },
  { id: 3, nom: "Projet 3", population: "R1" },
  { id: 4, nom: "Projet 4", population: "R2" },
  { id: 5, nom: "Projet 5", population: "R2" },
  { id: 6, nom: "Projet 6", population: "R1 & R2" },
  { id: 7, nom: "Projet 7", population: "R2" }
];
