import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { ENV } from "../core/env.config";
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { ProjetService } from "../service/projet.service";
import { error } from "protractor";
import { UserService } from "../service/user.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private user = {};

  constructor(private http: HttpClient,
    private router: Router,
    private userService: UserService) { }

  getCurrentUser() {
    const userstr = sessionStorage.getItem("currentUser");
    let user = {};
    if (userstr != undefined && userstr != "" && userstr != "null" && userstr != null) {
      user = JSON.parse(userstr);
    }
    return user;
  }

  public isAuthorized(): Boolean {
    var cond = sessionStorage.getItem("userIsAuthorized");
    var isAuth: boolean = false;

    if (!cond) {
      isAuth = false;
    } else {
      isAuth = JSON.parse(cond)
    }
    return isAuth;
  }

  public isAdmin(): boolean {
    let profile = sessionStorage.getItem("profile");

    return profile != undefined && profile === 'super_admin';
  }

  public redirect() {
    this.router.navigate(['/project/home']);
    // Redirection completed; clear redirect from storage
    this.clearRedirect();
  }

  public clearRedirect() {
    // Remove redirect from sessionStorage
    sessionStorage.removeItem('authRedirect');
  }

  public removeTokens() {
    sessionStorage.clear();
    localStorage.clear();
  }

  public logoff() {
    this.removeTokens();
    this.clearRedirect();
    this.router.navigate(['login']);
  }

  public login() {
    window.location.href = encodeURI(
      `${ENV.AUTH_DOMAIN}` + '?' +
      'response_type=' + `${ENV.RESPONSE_TYPE}` +
      '&state=' + `${ENV.STATE}` +
      '&scope=' + `${ENV.AUTH_SCOPE}` +
      '&redirect_uri=' + `${ENV.REDIRECT_URI}` +
      '&client_id=' + `${ENV.CLIENT_ID}`);
    event.preventDefault();
  }

  async TokenValid() {

    const now = Date.now() / 1000;
    let expires_at = JSON.parse(sessionStorage.getItem('expires_at'));
    if (expires_at === undefined || expires_at === null) {
      return false;
    }
    if (expires_at !== undefined && expires_at !== null && now > expires_at) {
      try {
        
        const refreshTokenReponse = await this.refreshAuthToken().toPromise() as any;
        if (refreshTokenReponse !== undefined && refreshTokenReponse !== null && refreshTokenReponse.access_token !== null) {
          const profile = jwt_decode<JwtPayload>(refreshTokenReponse.id_token);
          expires_at = profile.exp;
          sessionStorage.setItem('expires_at', JSON.stringify(expires_at));
          return true;
        }
      } catch (error) {
        console.log('error :', error);
      }
      return false;

    }
  }

  async checkAccesRights(res) {
    const id_token = res['id_token'];
    const acc_token = res['access_token'];
    const ref_token = res['refresh_token'];



    if (res.id_token != undefined) {

      const expires_at = res['exp'];
      sessionStorage.setItem('id_token', id_token);
      sessionStorage.setItem('access_token', acc_token);
      sessionStorage.setItem('refresh_token', ref_token);
      sessionStorage.setItem('expires_at', expires_at);
      sessionStorage.setItem('userIsAuthorized', 'true');

      sessionStorage.setItem('userUid', res['ipn']);

      if (res['authType'] == 'centrale') {
        this.user['uid'] = res['ipn'];
        this.user['userType'] = res.userType;
      }

      if (res['authType'] == 'dealer') {
        sessionStorage.setItem('userIdRrf', res['dealerProfile'].rrf);
        this.user['uid'] = res['dealerProfile'].ipn;
        this.user['userType'] = res['dealerProfile'].userType;
      }

      sessionStorage.setItem('userType', this.user['userType']);
      sessionStorage.setItem('userUid', this.user['uid']);
      this.userService.userTypeHasChanged(this.user['userType']);
      this.user['displayName'] = res['displayName'];
      this.user['firstName'] = res['firstName'];
      this.user['lastName'] = res['lastName'];
      this.userService.userHasChanged(this.user);
      sessionStorage.setItem('currentUser', JSON.stringify(this.user));
    }
    else {
      sessionStorage.setItem('userIsAuthorized', 'false');
    }

    this.redirect();
  }

  public validateCode(code) {
    const authType = sessionStorage.getItem('connexionType');
    return this.http.post(ENV.AUTH_URL + '/validate/token?auth_code=' + code + '&auth_type=' + authType, {});
  }

  public refreshAuthToken() {
    const refreshToken = sessionStorage.getItem('refresh_token');
    return this.http.post(ENV.AUTH_URL + '/refresh/token?refresh_token=' + refreshToken, {});
  }

  getAuthorizationToken(){
    return sessionStorage.getItem('id_token');
  }

}
