import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { NavigationComponent } from "./components/navigation/navigation.component";
import { LandingComponent } from "./components/landing/landing.component";
import { LoginComponent } from "./login/login.component";
import { TabDetailsComponent } from "./components/tab-details/tab-details.component";
import { ProjetsComponent } from "./components/projets/projets.component";
import { WkfChartComponent } from "./components/wkf-chart/wkf-chart.component";
import { WkfEventComponent } from "./components/wkf-event/wkf-event.component";
import { EditEventComponent } from "./components/edit-event/edit-event.component";
import { ListEventComponent } from "./components/list-event/list-event.component";
import { EditWkfComponent } from "./components/edit-wkf/edit-wkf.component";
import { ListWkfComponent } from "./components/list-wkf/list-wkf.component";
import { EditProjectComponent } from "./components/edit-project/edit-project.component";
import { ExcelHandlerComponent } from "./components/excel-handler/excel-handler.component"
import {CallbackComponent} from "./auth/callback/callback.component";
import {AuthGuard} from "./auth/auth.guard";
import {ProjectEventsProfilesComponent} from "./components/project-events-profiles/project-events-profiles.component";
import {ReferentielReseauComponent} from "./components/referentiel-reseau/referentiel-reseau.component";
import { UploadComponent } from "./components/upload/upload.component";

const routes: Routes = [
  { path: "login", component: LoginComponent, pathMatch: 'full' },
  { path: "auth_callback", component: CallbackComponent },
 
  {
    path: "project",
    component: NavigationComponent,

    children: [
      { path: "workflows", component: ListWkfComponent, pathMatch: 'full', },
      { path: "workflow", component: EditProjectComponent },
      { path: "events", component: ListEventComponent },
      { path: "profiles", component: ProjectEventsProfilesComponent },
      { path: "event", component: EditEventComponent },
      { path: "details", component: TabDetailsComponent },
      { path: "home", component: ProjetsComponent, pathMatch: 'full', },
      { path: "report", component: WkfChartComponent, pathMatch: 'full' },
      { path: "report/:projectId", component: WkfChartComponent },
      { path: "events/details", component: WkfEventComponent },
      { path: "landing", component: LandingComponent },
      { path: "import", component: ExcelHandlerComponent },
      { path: "rrf", component: ReferentielReseauComponent },
      { path: "upload", component: UploadComponent },
      {
        path: 'partenaires',
        loadChildren: () => import('./components/partenaire/partenaire.module').then(m => m.PartenaireModule)
      },
    ],
    canActivate: [AuthGuard]
  },

  {
    path: "",
    redirectTo: "/project/home",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
