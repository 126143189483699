import { Component, OnInit } from "@angular/core";
import { ProjetService } from "../../service/projet.service";

@Component({
  selector: "app-list-event",
  templateUrl: "./list-event.component.html",
  styleUrls: ["./list-event.component.css"],
})
export class ListEventComponent implements OnInit {
  eventlist: any = [];
  projet: any;
  eventDet: any;
  isshow: boolean = false;
  userType: any;

  constructor(private prjSer: ProjetService) {}

  ngOnInit() {
    this.projet = sessionStorage.getItem("nomProjet");
    if (this.projet) {
       this.prjSer.getProjet(this.projet).subscribe((res) => {
      this.eventlist = res[0]['event_list'];
    });
    }
   
    
    this.userType = JSON.parse(sessionStorage.getItem("userName"));
  }

  getEvent(eventname) {
    this.eventDet = this.eventlist.filter((event) => event.name === eventname);
    this.isshow = true;
  }
}
