import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from "@angular/core";
import { ProjetService } from "../../service/projet.service";
import Swal from "sweetalert2";
import { DndDropEvent, DropEffect } from "ngx-drag-drop";
import { EventAttribute, wkf } from "src/app/models/global.model";
import { Router } from "@angular/router";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { v4 as uuidv4 } from "uuid";
import { MatDialog } from "@angular/material/dialog";
import { EditEventComponent } from "../edit-event/edit-event.component";

@Component({
  selector: "app-edit-wkf",
  templateUrl: "./edit-wkf.component.html",
  styleUrls: ["./edit-wkf.component.css"],
})
export class EditWkfComponent implements OnInit, OnChanges {
  @Input() project?: any;
  @Output() projectChange = new EventEmitter<any>();
  baseEvents: any = [];
  specEvents: any = [];
  eventDrop: any = [];
  evName: any = new Set();
  isExistProjName: boolean = false;
  public eventName = "";
  public projectForm: UntypedFormGroup;
  public linkForm: UntypedFormGroup;
  public editEventForm: UntypedFormGroup;
  public default: string = "R1";
  projectName: any;
  eventDet: any;
  isshow: boolean = false;
  userType: any;
  public isEditMode;
  public typePopulations: string[] = ["R1", "R2", "R1 & R2"];
  public selectedEvent;
  projectEvents: any[] = [];
  value: any = { label: '', value: '' };

  modelFields: Array<EventAttribute> = [];
  model: any = {
    name: "",
    description: "",
    theme: {
      bgColor: "ffffff",
      textColor: "555555",
      bannerImage: "",
    },
    dependecies: [],
    attributes: this.modelFields,
    currentStatus: 'Verrouillé'
  };
  isProjectNameSet: boolean = false;

  constructor(
    private projectService: ProjetService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private ref: ChangeDetectorRef,
    private matDialog: MatDialog
  ) {
    this.editEventForm = this.fb.group({
      eventName: ["", Validators.required],
      description: ["", Validators.required]
    });

    this.projectForm = this.fb.group({
      projectName: ["", Validators.required],
    });
    this.linkForm = this.fb.group({
      lien: ["", Validators.required],
    });
    if (this.project) {
      this.initEditionData();
    }
  }

  ngOnInit() {
    this.getBaseEvents();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.project && !changes.project.previousValue && changes.project.currentValue) {
      this.initEditionData();
    }
  }

  initEditionData() {
    this.isEditMode = true;
    this.projectEvents = this.project.projectEvents;
    this.projectName = this.project.projectName;
    this.specEvents = this.project.specEvents;
    this.projectForm.get('projectName').setValue(this.projectName);
  }

  getBaseEvents() {
    this.projectService.getBaseEvents().then((response) => {
      this.baseEvents = response ? response.result : [];
      this.baseEvents.forEach(baseEvent => {
        baseEvent.type = 1;
      });
    });
  }

  getSpecEvents() {
    this.projectService.getSpecEvents(this.projectName).then((response) => {
      this.specEvents = response;
    });
  }

  addValue(values) {
    values.push(this.value);
    this.value = { label: "", value: "" };
  }

  get projetFormFormControl() {
    return this.projectForm.controls;
  }

  onDragEnd(event: DragEvent) {
  }

  onDragged(item: any, list: any[], effect: DropEffect) {
    if (effect === "move") {
      const index = list.indexOf(item);
      list.splice(index, 1);
      this.checkFormValidityAndEmitChanges();
    }
  }

  checkFormValidityAndEmitChanges() {
    const isFormValid = this.projectName && this.projectEvents && this.projectEvents.length > 0;
    this.projectChange.emit({
      projectName: this.projectName,
      projectEvents: this.projectEvents,
      specEvents: this.specEvents,
      isStepValid: isFormValid
    });
  }

  editEvent(event: any) {
    const dialogRef = this.matDialog.open(EditEventComponent, {
      data: {
        type: event.type ? event.type : 2,
        isEditionMode: true,
        event: event
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result?.event) {
        event = result.event;
        if (result.specEvent) {
          this.specEvents[this.specEvents.findIndex(element => element.event_id == event.event_id)] = event;
        } else {
          let baseEvent = this.baseEvents.find(element => element.event_id == event.event_id);
          baseEvent.name = event.name;
          baseEvent.attributes = event.attributes;
          baseEvent.description = event.description;
          this.projectService.updateBaseEvent(baseEvent).then(data => {
            this.getBaseEvents();
          })
        }
        this.projectEvents[this.projectEvents.findIndex(element => element.event_id == event.event_id)] = event;
        if (result.deleted) {
          this.projectEvents.splice((this.projectEvents.findIndex(element => element.event_id == event.event_id)), 1);
          if (result.specEvent) {
            this.specEvents.splice((this.specEvents.findIndex(element => element.event_id == event.event_id)), 1);
          } else
            this.baseEvents.splice((this.baseEvents.findIndex(element => element.event_id == event.event_id)), 1);
        }
      }
    });
  }

  onDrop(event: DndDropEvent) {
    const data = Object.assign({}, event.data);
    if (!data.projectEventId) {
      data.projectEventId = uuidv4();
    }
    data.dependencies = [];
    let position = event.index + 1;
    data.position = position;
    if (position === 1) {
      data.status = 'En Cours';
    } else {
      data.status = 'Verrouillé'
    }
    this.projectEvents.splice(event.index, 0, data);
    this.checkFormValidityAndEmitChanges();
  }

  removeField(i) {
    this.projectEvents.splice(i, 1);
    this.checkFormValidityAndEmitChanges();
  }

  createEvent(type: any) {
    const dialogRef = this.matDialog.open(EditEventComponent, {
      data: {
        projectName: this.projectName,
        type: type,
        specEvents: this.specEvents
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.specEvent) {
        this.specEvents.push(result.event);
      } else {
        this.getBaseEvents();
      }
    });
  }

  onProjectNameChange(event) {
    if (event.target.value && event.target.value != '') {
      // check name if exist
      // this.projectService.findProjectWithSameName(event.target.value.toString().toLocaleLowerCase())
      //     .then(response => {
      //       this.isExistProjName = response && response.length > 0;
      this.isProjectNameSet = !this.isExistProjName;
      this.projectName = this.isProjectNameSet ? event.target.value : undefined;
      this.checkFormValidityAndEmitChanges();
    } else {
      this.isProjectNameSet = false;
      this.isExistProjName = false;
      this.projectName = undefined;
      this.checkFormValidityAndEmitChanges();
    }
  }
}
