import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import {AuthService} from "../auth/auth.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {

  public isError: boolean = false;

  constructor(private router: Router, private authService: AuthService) {
    sessionStorage.clear();
  }

  authLogin() {
    this.authService.login();
  }

  ngOnInit() {
    sessionStorage.clear();
  }
}
