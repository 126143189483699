import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef } from '@angular/core';
import { ProjetService } from 'src/app/service/projet.service';
import { ToastrService } from 'ngx-toastr';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PartenaireService } from '../partenaire/partenaire.service';
@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {
  tabs: any = ['RRF Enrichie - Groupe/Plaque', 'Liste des fonctions', "Affaire à supprimer", "Affaire à ajouter"];
  rrfSupprimer = [];
  rrfAjouter = []
  tabs_lib: any = ['Groupe'];
  selected: any = 0;
  modalTitle: string = "Mis à jour";
  isAdd: boolean = true;
  typePartenaire: string;
  modalRef?: BsModalRef;
  message?: string;
  rrftoDelete: any;
  idPagination1 = "id1";
  idPagination2 = "id2";
  //Delete Affaire
  isRrfDeleteAllChecked: boolean = false;
  isMoreThanOneCheckedForDelete: boolean = false;
  isAllAffairesCheckedDelete: boolean = false;
  checkedMoreListForDelete: any = []
  //Add Affaire
  rrfToAdd: any;
  isRrfAddAllChecked: boolean = false;
  isMoreThanOneCheckedForAdd: boolean = false;
  isAllAffairesCheckedAdd: boolean = false;
  checkedMoreListForAdd: any = []
  p: number = 1;
  itemsPerPage = 8;
  constructor(private service: ProjetService, private cd: ChangeDetectorRef, private modalService: BsModalService, private toaster: ToastrService, private partenaireService: PartenaireService) { }
  ngOnInit(): void {
    this.getAffaires()
  }
  getAffaires() {
    this.service.getAffairesList('new').then(res => {
      this.rrfAjouter = res;
      this.rrfAjouter = this.rrfAjouter.map(r => ({ ...r, 'state': false }));

    });

    this.service.getAffairesList('old').then(res => {
      this.rrfSupprimer = res;
      this.rrfSupprimer = this.rrfSupprimer.map(r => ({ ...r, 'state': false }));
    })
  }
  openFileSelector(event) {
    $(".p-fileupload-choose input[type=file]").trigger('click');
  }

  uploadFile(event: any, form: any, name) {
    let file = event.files[0];
    this.service.upload(file, name).subscribe(async res => {
      if (res) {
        form.clear();
        var resUpdateDms = await this.service.updateDmsInEntities();
        var resUpdateDmsPartner = await this.service.updateDmsInPartners();
        this.toaster.success('Fichier importé !');
        this.getAffaires();
      }
    })
  }

  uploadFileArca(event: any, form: any) {
    let file = event.files[0];
    this.service.uploadArca(file).subscribe(async res => {
      if (res) {
        form.clear();
        this.toaster.success('Fichier importé !');
      }
    })
  }

  async download(i: any) {
    let res;
    if (i == 0) {
      res = await this.service.getAllAffaires();
    } else {
      res = await this.service.getAllUsers();
    }
    if (res) {
      this.service.exportAsExcelFile(res, this.tabs[i])
      this.toaster.success('Fichier téléchargé !');
    }
  }

  openModal(template: TemplateRef<any>, rrf, type) {
    if (type === "add") {
      this.rrfToAdd = rrf
    } else {
      this.rrftoDelete = rrf;
    }

    this.modalRef = this.modalService.show(template, { class: 'modal-dialog-centered modal-sm' });
  }

  confirm(saveOrDelete: string): void {
    this.message = 'Confirmed!';
    if (saveOrDelete === "save") {
      let temp = this.rrfAjouter.filter(part => part['code_entite'] === this.rrfToAdd.code_entite);
      this.service.addRrf(temp[0]).then(res => {
        if (res) {
          this.rrfAjouter = this.rrfAjouter.filter(part => part['code_entite'] !== this.rrfToAdd.code_entite);
          this.toaster.success("L'affaire à été bien ajouter");
        }
      })
    } else {
      let temp = this.rrfSupprimer.filter(part => part['code_entite'] === this.rrftoDelete.code_entite);
      this.service.deleteRrf(temp[0]).then(res => {
        if (res) {
          this.rrfSupprimer = this.rrfSupprimer.filter(part => part['code_entite'] !== this.rrftoDelete.code_entite);
          this.toaster.success("L'affaire à été bien supprimé");
        }
      })
    }
    this.modalRef?.hide();
  }

  decline(saveOrDelete): void {
    this.message = 'Declined!';
    this.modalRef?.hide();
  }

  isAllChecked(type) {
    if (type === "Add") {
      return this.rrfAjouter.every(_ => _.state);
    } else {
      return this.rrfSupprimer.every(_ => _.state);
    }
  }
  checkDelete(ev, id) {
    if (ev.target.checked) {
      this.checkedMoreListForDelete.push(id)
    }
    if (!ev.target.checked) {
      this.checkedMoreListForDelete = this.checkedMoreListForDelete.filter(i => i !== id);
    }
    if (this.checkedMoreListForDelete.length > 0) {
      this.isMoreThanOneCheckedForDelete = true
    }
    if (this.checkedMoreListForDelete.length < 1) {
      this.isMoreThanOneCheckedForDelete = false
    }
    for (let affaire of this.rrfSupprimer) {
      if (affaire.code_entite === id) {
        affaire.state = ev.target.checked;
      }
    }
  }


  checkAdd(ev, id) {
    if (ev.target.checked) {
      this.checkedMoreListForAdd.push(id)
    }
    if (!ev.target.checked) {
      this.checkedMoreListForAdd = this.checkedMoreListForAdd.filter(i => i !== id);
    }
    if (this.checkedMoreListForAdd.length > 0) {
      this.isMoreThanOneCheckedForAdd = true
    }
    if (this.checkedMoreListForAdd.length < 1) {
      this.isMoreThanOneCheckedForAdd = false
    }
    for (let affaire of this.rrfAjouter) {
      if (affaire.code_entite === id) {
        affaire.state = ev.target.checked;
      }
    }
  }

  DeleteAllRrfs() {
    let rrfsList = this.rrfSupprimer.filter(rrf => rrf.hasOwnProperty('state') && rrf.state === true);
    this.service.deleteAllRrf(rrfsList).then(res => {
      if (res['success']) {
        for (let rrf of rrfsList) {
          this.rrfSupprimer = this.rrfSupprimer.filter(rrfS => rrfS.code_entite !== rrf.code_entite);
        }
        this.isAllAffairesCheckedDelete = false;
        this.isMoreThanOneCheckedForDelete = false;
        this.checkedMoreListForDelete = [];
        this.toaster.success("Les Affaires ont été supprimé avec succès");

      }
    })
  }
  AddAllRrfs() {
    let rrfsList = this.rrfAjouter.filter(rrf => rrf.hasOwnProperty('state') && rrf.state === true);
    this.service.addAllRrf(rrfsList).then(res => {
      if (res['success']) {
        for (let rrf of rrfsList) {
          this.rrfAjouter = this.rrfAjouter.filter(rrfA => rrfA.code_entite !== rrf.code_entite);
        }
        this.isAllAffairesCheckedAdd = false;
        this.isMoreThanOneCheckedForAdd = false;
        this.checkedMoreListForAdd = [];
        this.toaster.success("Les Affaires ont été ajouté avec succès");

      }
    })
  }


  checkDeleteAll(ev) {
    this.isRrfDeleteAllChecked = ev.target.checked;
    this.isMoreThanOneCheckedForDelete = ev.target.checked;
    for (let rrf of this.rrfSupprimer) {
      rrf.state = ev.target.checked;
      if (ev.target.checked) this.checkedMoreListForDelete.push(rrf.code_entite);
      if (!ev.target.checked) this.checkedMoreListForDelete = this.checkedMoreListForDelete.filter(i => i !== rrf.code_entite);
    }
  }
  checkAddAll(ev) {
    this.isRrfAddAllChecked = ev.target.checked;
    this.isMoreThanOneCheckedForAdd = ev.target.checked;
    for (let rrf of this.rrfAjouter) {
      rrf.state = ev.target.checked;
      if (ev.target.checked) this.checkedMoreListForAdd.push(rrf.code_entite);
      if (!ev.target.checked) this.checkedMoreListForAdd = this.checkedMoreListForAdd.filter(i => i !== rrf.code_entite);
    }

  }
}
