import { Component, OnInit, Input, Output, EventEmitter, Inject } from "@angular/core";
import { Event, EventAttribute, EventAttributeValue } from "../../models/global.model";
import Swal from "sweetalert2";
import { DndDropEvent, DropEffect } from "ngx-drag-drop";
import { ProjetService } from "../../service/projet.service";
import { ToastrService } from "ngx-toastr";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { v4 as uuidv4 } from "uuid";
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-edit-event",
  templateUrl: "./edit-event.component.html",
  styleUrls: ["./edit-event.component.css"],
})
export class EditEventComponent implements OnInit {
  public value: EventAttributeValue;
  public success = false;
  public isExistBase = false;
  public isEditEvent = false;
  public eventAttributes: EventAttribute[];
  event: Event;
  projectName: any;
  report = false;
  reports: any = [];
  eventForm: UntypedFormGroup;
  isFormValid: boolean = false;
  eventspeclist: any = [];
  needToVerifyEventName: boolean;
  isEditionMode: boolean;
  submitted: boolean = false;
  file: any;
  specEvents: any;

  constructor(
    private projectService: ProjetService,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditEventComponent>
  ) {
    this.eventForm = this.fb.group({
      eventName: ["", Validators.required],
      eventDescription: ["", Validators.required],
    });
    this.dialogRef.disableClose = true;
    this.value = { label: '', value: '' };
    this.projectService.loadEventsAttributes().then(jsonFileContent => {
      if (jsonFileContent) {
        this.eventAttributes = jsonFileContent.attributes;
      }
    });
  }

  ngOnInit() {
    this.isEditionMode = this.data.isEditionMode;
    this.event = this.data.isEditionMode ? JSON.parse(JSON.stringify(this.data.event)) : new Event();
    if (this.isEditionMode) {
      this.eventForm.controls['eventName'].setValue(this.event.name);
      this.eventForm.controls['eventDescription'].setValue(this.event.description);
    }
    this.projectName = this.data.projectName;
    this.needToVerifyEventName = this.data.type === 1;
    this.specEvents = this.data.specEvents;
  }

  onEventNameChange(event) {
    if (event.target.value) {
      this.projectService.findEventProjectWithSameName(event.target.value.toString().toLocaleLowerCase())
        .then(response => {
          this.isExistBase = response && response.result.length > 0;
          if (!this.isExistBase) {
            this.isExistBase = this.specEvents.length > 0 && this.specEvents.some(s => s.name.toLocaleLowerCase() == event.target.value.toString().toLocaleLowerCase())
          }
          this.event.name = this.isExistBase ? '' : event.target.value;
          console.log(this.event.name)
        });

    } else {
      this.event.name = undefined;
    }
  }

  onEventDescriptionChange(event) {
    if (event.target.value) {
      this.event.description = event.target.value
    } else {
      this.event.description = undefined;
    }
  }

  onDragEnd(event: DragEvent) {
  }

  onDragged(item: any, list: any[], effect: DropEffect) {
    if (effect === "move") {
      const index = list.indexOf(item);
      list.splice(index, 1);
    }
  }

  onDrop(event: DndDropEvent) {
    const data = Object.assign({}, event.data);
    data._id = uuidv4();
    if (data.type == 'template') {
      data.uploadRequired = true;
      data.templateLabel = "Remplir le fichier et réimporter";
    }
    this.event.attributes.push(data);
  }

  private checkFormValidity() {
    return this.event.name && this.event.description && this.event.attributes.length > 0;
  }

  addValue(values) {
    values.push(this.value);
    this.value = { label: "", value: "" };
  }

  removeValue(values: any, valueIndex: any) {
    values.splice(valueIndex, 1)
  }

  removeField(i: any) {
    Swal.fire({
      text: "Voulez-vous vraiment supprimer?",
      showCancelButton: true,
      confirmButtonColor: "#00B96F",
      confirmButtonText: "Oui",
      cancelButtonText: "Annuler",
      reverseButtons: true
    }).then((result) => {
      if (result && result.value) {
        this.event.attributes.splice(i, 1);
      }
    });
  }

  toggleValue(item) {
    item.selected = !item.selected;
  }

  createEvent() {
    if (this.needToVerifyEventName) {
      this.createBaseEvent();
    } else {
      // this.saveSpecificEvent();
      this.event.id = uuidv4();
      this.event['event_id'] = this.event.id;
      this.dialogRef.close({ event: this.event, specEvent: true });
    }
  }

  saveSpecificEvent() {
    this.event.project_name = this.data.projectName;
    this.projectService.createSpecificEvent(this.event)
      .then(response => {
        this.toastr.success('l\'évènement ' + this.event.name + ', a été créé.');
        this.dialogRef.close({ event: this.event, saved: true });
      });
  }

  onChange(event) {
    this.file = event.target.files[0];
  }

  download(item) {
    this.projectService.downloadFile(item.filepath, item.filename);
    this.toastr.success('Le fichier a été téléchargé.');

  }

  saveEvent() {
    if (this.event.attributes.filter(a => a.type == 'template').length > 0) {
      if (this.file) {
        const fichier = new FormData();
        fichier.append('file', this.file, Date.now() + "_" + this.file.name);
        this.projectService.uploadFile(fichier).then(response => {
          if (response) {
            this.event.attributes.filter(e => e.type == 'template')[0]['filename'] = this.file.name;
            this.event.attributes.filter(e => e.type == 'template')[0]['filepath'] = response.result;
            this.file = null;
            this.createEvent();
          }
        });
      } else {
        this.toastr.error('Veuilliez déposer le template!');
      }
    } else {
      this.createEvent();
    }
  }


  createBaseEvent() {
    this.projectService.createBaseEvent(this.event)
      .then(response => {
        this.toastr.success('l\'évènement ' + this.event.name + ' a été créé.');
        this.dialogRef.close({ event: this.event, saved: true });
      });
  }

  updateEvent() {
    Swal.fire({
      text: "Voulez-vous sauvegarder les modifications cet évènement?",
      showCancelButton: true,
      confirmButtonColor: "#00B96F",
      reverseButtons: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then((result) => {
      if (result.value) {
        this.dialogRef.close({ event: this.event, specEvent: this.event.id != null });
      }
    });
  }


  deleteEvent() {
    Swal.fire({
      text: "Voulez-vous supprimer définitivement cet évènement?",
      showCancelButton: true,
      confirmButtonColor: "#00B96F",
      reverseButtons: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (this.event.id) {
          if (this.event.attributes.filter(a => a.type == 'template').length > 0) {
            let path = this.event.attributes.filter(e => e.type == 'template')[0]['filepath'];
            await this.projectService.deleteFile(path);
          }
          this.dialogRef.close({ event: this.event, deleted: true, specEvent: true });
        } else {
          this.projectService.deleteBaseEvent(this.event)
            .then(response => {
              if (response) {
                this.toastr.success('l\'évènement ' + this.event.name + ' a été supprimé.');
                this.dialogRef.close({ event: this.event, deleted: true });
              }
            });
        }
      } else {
        this.dialogRef.close();
      }
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
