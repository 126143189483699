import { Component, OnInit } from '@angular/core';
import { PartenaireService } from "../partenaire/partenaire.service";
import { ToastrService } from "ngx-toastr";
import { error } from "protractor";

@Component({
  selector: 'app-referentiel-reseau',
  templateUrl: './referentiel-reseau.component.html',
  styleUrls: ['./referentiel-reseau.component.css']
})
export class ReferentielReseauComponent implements OnInit {

  constructor(private partenaireService: PartenaireService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  chargerDMS() {
    this.partenaireService.loadDMSFromVectury().toPromise().then((result: any) => {
      if (result) {
        this.toastr.info(result.updated + ' dms trouvés, ' + result.notfound + ' non trouvés, sur ' + result.row_count + ' rrf', 'Mis à jour DMS');
      }
    });
  }
}
