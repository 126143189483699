export const profiles: Array<string> = [
    'user1@renault.com',
    'user2@renault.com',
    'user12@renault.com',
    'user23@renault.com',
    'user45@renault.com',
    'user56@renault.com',
    'user89@renault.com',
    'user10@renault.com',
];


export const responsableProfiles: Array<string> = [
    'responsable1@renault.com',
    'responsable2@renault.com',
    'responsable12@renault.com',
    'responsable23@renault.com',
    'responsable45@renault.com',
    'responsable56@renault.com',
    'responsable89@renault.com',
    'responsable10@renault.com',
];