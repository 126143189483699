import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import ApexCharts from "apexcharts/dist/apexcharts.common.js";
import { ChartComponent } from "ng-apexcharts";

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart
} from "ng-apexcharts";
import { ToastrService } from 'ngx-toastr';
import { ProjetService } from 'src/app/service/projet.service';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
};
export type ChartOptions5 = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
};

@Component({
  selector: "app-wkf-chart",
  templateUrl: "./wkf-chart.component.html",
  styleUrls: ["./wkf-chart.component.css"],
})
export class WkfChartComponent implements OnInit {

  public colors = ['#00E396', '#F9CE1D', '#C4BBAF', '#EA3546'];
  public randomColors = [];
  public chartOptions;
  public seriesList: any = [];
  public drList: any = [];
  public drsPercentageList: any = [];
  public chartOptions5;
  public pieText: string = "";
  public projectsNamesList = [];
  public isLoadingChart1 = true;
  public isLoadingChart2 = true;
  public isLoadingChart3 = true;
  public isLoadingChart4 = true;
  public isLoadingChart5 = true;
  public isLoadingDonut1 = true;
  public isLoadingOnUpdate = false;
  public projectName = "";
  public selectedProject = "";
  @ViewChild("chart", { static: false }) chart: ChartComponent;

  constructor(private projectService: ProjetService, private toastr: ToastrService, private route: ActivatedRoute) {

    this.projectService.projectListObj$.subscribe(list => {
      this.projectsNamesList = list;
      this.projectsNamesList.sort();
      this.selectedProject = list[0];
      this.getDonutPercantage(list[0]);
    })

    this.route.params.subscribe(params => {
      if (params['projectId'] !== undefined && params['projectId'] !== null) {
        this.projectName = params['projectId'];
        this.selectedProject = this.projectName;
        this.getDonutPercantage(this.projectName);
      }
    });

    if ('projects' in sessionStorage) {
      this.projectsNamesList = JSON.parse(sessionStorage.getItem('projects'));
      this.projectsNamesList.sort();
    }

    if ('selectedProject' in sessionStorage) {
      this.selectedProject = JSON.parse(sessionStorage.getItem('selectedProject'))
    }

    this.chartOptions5 = {
      series: [],
      chart: {
        width: 350,
        type: "pie",

      },
      labels: [],
      title: {
        text: 'Gradient Donut with custom Start-angle'
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "top"
            }
          }
        }
      ]
    };
  }


  @HostListener('window:scroll', ['$event']) scrollHandler($event) {

    const navbar = document.getElementById("myTopnav");
    const verticalOffset = window.pageYOffset
      || document.documentElement.scrollTop
      || document.body.scrollTop || 0;

    if (verticalOffset > 100) {
      navbar.classList.add("sticky")
    } else {
      navbar.classList.remove("sticky");
    }
  }
  getDonutPercantage(project) {
    this.projectService.getPercentage(project).subscribe(list => {
      this.isLoadingDonut1 = false;
      this.seriesList = list;
      this.chartOptions = {
        plotOptions: {
          pie: {
            donut: {
              size: '33%',
            }
          }
        },
        series: list,
        chart: {
          type: "donut",
          size: '32%',
        },
        labels: ["Validé", "En Cours", "Verrouillé", "Refusé"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
                height: 300,
              },
              legend: {
                position: "center",
                show: false


              }
            }
          }
        ]
      };
    });
  }


  getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };


  ngOnInit() {
    let isUpdate = false;
    this.getEventsPercentage(this.selectedProject, isUpdate);
    this.getDrValidatedEvents(this.selectedProject, isUpdate);
    this.getDrTotalValidatedEvents(this.selectedProject, isUpdate);
    this.getMonthlyEventCount(this.selectedProject, isUpdate);
  }


  onChangeProject(projectName) {
    this.isLoadingOnUpdate = true;
    let isUpdate = true;
    this.getDonutPercantage(projectName);
    this.getEventsPercentage(projectName, isUpdate);
    this.getDrValidatedEvents(projectName, isUpdate);
    this.getDrTotalValidatedEvents(projectName, isUpdate);
    this.getMonthlyEventCount(projectName, isUpdate);
    this.isLoadingOnUpdate = false;
  }

  getMonthlyEventCount(projectName, isUpdate) {
    this.projectService.getMonthlyEventCount(projectName).subscribe(eventsObj => {
      let eventsData = eventsObj['monthlyEventsList'].map(event => event.percentage);
      let monthsList = eventsObj['monthlyEventsList'].map(event => event.month);
      this.loadBarChart3(eventsData, monthsList, isUpdate);
      this.isLoadingChart3 = false;
    });
  }

  getDrTotalValidatedEvents(projectName, isUpdate) {
    this.projectService.getDrTotalValidatedEvents(projectName).subscribe(res => {
      let backgroundColors3 = [];
      this.drsPercentageList = res['drspercentageList'];
      let drsList = res['drspercentageList'].map(dr => dr.dr);
      let percentageList = res['drspercentageList'].map(dr => dr.percentage);
      drsList.forEach(item => {
        backgroundColors3.push('#ccc');
      })
      this.loadChart4(drsList, percentageList, backgroundColors3, isUpdate);
      this.getSelectedBar(0, this.selectedProject, false);
      this.isLoadingChart4 = false;
    })

  }

  getDrValidatedEvents(projectName, isUpdate) {
    this.projectService.getDrValidatedEvents(projectName).subscribe(response => {
      let backgroundColors2 = [];
      this.drList = response['drList'].map(dr => dr.dr);
      let drsValuesdata = response['drListValues'][0].data;
      drsValuesdata.forEach(item => {
        backgroundColors2.push('#ccc');
      });

      this.loadBarchart2(response['drListValues'], this.drList, response['eventCount'], backgroundColors2, isUpdate);
      this.drList.forEach(dr => {
        let color = this.getRandomColor();
        this.randomColors.push(color);
      });
      this.isLoadingChart2 = false;
    });

  }

  getEventsPercentage(projectName, isUpdate) {
    this.projectService.getValidatedEventsPercentage(projectName).subscribe(response => {
      let list = [];
      let percentageList = response['eventsCountList'].map(event => event.percentage != null ? event.percentage : 0);
      response['eventsList'].forEach(event => list.push(event.event_name));
      let backgroundColors1 = [];
      list.forEach(item => {
        backgroundColors1.push('#ccc');
      })

      this.loadBarChart1(list, percentageList, backgroundColors1, isUpdate);

      this.isLoadingChart1 = false;
    });

  }
  getSelectedBar(index, project, isUpdate) {
    if (index !== undefined && index !== "undefined") {
      let slectedDr = this.drsPercentageList[index];
      if (typeof slectedDr === 'undefined') {
        this.toastr.info("veuillez cliquer sur la barre", '', {
          timeOut: 1000,
        });
      } else {
        this.projectService.getSingleDrEventsState(slectedDr, project).subscribe(res => {
          this.isLoadingChart5 = false;
          let percentageList = res['resultList'].map(result => result.percentage);
          let statusList = res['resultList'].map(result => result.status);
          let dr = res['dr'];

          this.chartOptions5.series = percentageList;
          this.chartOptions5.colors = this.colors;
          this.chartOptions5.labels = statusList;
          this.chartOptions5.title.text = dr;
          this.pieText = "Avancement dans la DR de " + dr;
        })
      }

    }

  }
  loadBarChart1(eventsList, eventsData, backgroundColors, isUpdate?: boolean) {

    var options = {
      series: [
        {
          name: "Validé",
          data: eventsData,
        },
      ],
      chart: {
        height: 320,
        type: "bar",
        events: {
          click: function (chart, w, e) {
          },
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "15%",
          distributed: true,
          dataLabels: {
            position: 'top',
          },

          colors: {
            ranges: [{
              from: 0,
              to: 0,
              color: '#ccc'
            }],
            backgroundBarColors: backgroundColors,
            backgroundBarOpacity: .4,
            backgroundBarRadius: 0,
          },
        },
      },
      grid: {
        show: false
      },
      labels: {
        show: true,
        align: 'top',
        minWidth: 0
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + "%";
        },
        offsetY: -60,
        style: {
          fontSize: '11px',
          colors: ["#000"]
        }
      },
      legend: {
        show: false,
        position: 'bottom',
      },
      yaxis: {
        floating: false,
        title: {
          align: 'center',
          text: "Pourcentage d'EV validés"
        },
        labels: {
          show: false
        },
        min: 0,
        max: 100,
      },
      fill: {
        opacity: 1
      },
      xaxis: {
        categories: eventsList,
        title: {
          text: "événement"
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            // colors: "colors",
            fontSize: "11px",
          },
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + "%";
          }
        }
      }
    };


    var chart = new ApexCharts(document.querySelector("#chart1"), options);
    chart.render();

    if (isUpdate) {

      chart.updateOptions({
        series: [{
          data: eventsData
        }],
        xaxis: {
          categories: eventsList,
          title: {
            text: "événement"
          }
        },
      })
    }

  }
  loadBarchart2(valuesList, drList, eventCount, backgroundColors, isUpdate) {
    var options = {

      series: valuesList,
      chart: {
        type: 'bar',
        height: 320,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          distributed: false,
          columnWidth: "85%",
          dataLabels: {
            position: 'top',
            hideOverflowingLabels: false,
            orientation: 'vertical'
          },

          colors: {
            ranges: [{
              from: 0,
              to: 0,
              color: '#ccc'
            }],
            backgroundBarColors: backgroundColors,
            backgroundBarOpacity: .3,
            backgroundBarRadius: 0,
          },
        },
      },
      dataLabels: {
        enabled: true,
        distributed: true,
        formatter: function (val) {
          return val + "%";
        },
        offsetY: 20,
        offsetX: 0,
        style: {
          fontSize: '11px',
          colors: ["#1f2532"]
        }
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        categories: drList,
        labels: {
          show: true,
          rotate: -30,
          style: {
            colors: [],
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 400,
            cssClass: 'apexcharts-xaxis-label',
          },
        },
        title: {
          text: 'EV/DR'
        }
      },
      yaxis: {
        title: {
          text: "Pourcentage d'EV validés"
        },
        labels: {
          show: false
        },
        floating: false,
        min: 0,
        max: 100,
      },
      fill: {
        opacity: 1
      },
      grid: {
        show: false
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + "%";
          }
        }
      }
    };

    var chart = new ApexCharts(document.querySelector("#chart2"), options);
    chart.render();
    if (isUpdate) {
      chart.updateOptions({

        series: valuesList,
        chart: {
          type: 'bar',
          height: 320,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            distributed: false,
            columnWidth: "85%",
            dataLabels: {
              position: 'top',
              hideOverflowingLabels: false,
              orientation: 'vertical'
            },

            colors: {
              ranges: [{
                from: 0,
                to: 0,
                color: '#ccc'
              }],
              backgroundBarColors: backgroundColors,
              backgroundBarOpacity: .3,
              backgroundBarRadius: 0,
            },
          },
        },
        dataLabels: {
          enabled: true,
          distributed: true,
          formatter: function (val) {
            return val + "%";
          },
          offsetY: 20,
          offsetX: 0,
          style: {
            fontSize: '11px',
            colors: ["#1f2532"]
          }
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff']
        },
        xaxis: {
          categories: drList,
          labels: {
            show: true,
            rotate: -30,
            style: {
              colors: [],
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            },
          },
          title: {
            text: 'EV/DR'
          }
        },
        yaxis: {
          title: {
            text: "Pourcentage d'EV validés"
          },
          labels: {
            show: false
          },
          floating: false,
          min: 0,
          max: 100,
        },
        fill: {
          opacity: 1
        },
        grid: {
          show: false
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + "%";
            }
          }
        }

      })
    }
  }
  loadBarChart3(eventsData, monthsList, isUpdate) {
    var options = {
      series: [{
        name: "validé",
        data: eventsData
      }],
      chart: {
        height: 320,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + "%";
        }
      },
      stroke: {
        curve: 'smooth',
        width: 3
      },
      colors: ['#4CAF50'],
      yaxis: {
        title: {
          text: "pourcentage d'EV validés"
        },
        labels: {
          show: false
        },
        max: 100,
      },
      grid: {
        show: false
      },
      xaxis: {
        categories: monthsList,
        title: {
          text: "Année"
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + "%";
          }
        }
      }

    };

    var chart = new ApexCharts(document.querySelector("#chart3"), options);
    chart.render();
    if (isUpdate) {
      chart.updateOptions({
        series: [{
          data: eventsData
        }]
      })
    }
  }
  loadChart4(drList, data, backgroundColors3, isUpdate) {
    var that = this;
    var options = {
      colors: ['#F7A84B'],
      series: [{
        name: "Validé",
        data: data
      }],
      chart: {
        type: 'bar',
        height: 350,
        events: {
          click: function (event, chartContext, config) {
            that.getSelectedBar(config.dataPointIndex, that.selectedProject, true);

          }
        },

      },
      plotOptions: {
        bar: {
          columnWidth: '15%',
          height: '30px',
          horizontal: true,
          dataLabels: {
            position: 'top',
            offsetX: 50
          },
          colors: {
            ranges: [{
              from: 0,
              to: 0,
              color: '#ccc'
            }],
            backgroundBarColors: backgroundColors3,
            backgroundBarOpacity: .4,
            backgroundBarRadius: 0,
          },
        }
      },
      grid: {
        show: false,
      },

      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + "%";
        },
        offsetX: 50,
        style: {
          fontSize: '11px',
          colors: ['#000']
        }
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        categories: drList,
        labels: {
          show: false,
        },
        max: 100,
        title: {
          text: "Pourcentage d'EV validés",
          offsetX: 0,
          offsetY: -20
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        }
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      title: {
        text: "Avancement dans la DR"
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + "%";
          }
        }
      }

    };


    var chart = new ApexCharts(document.querySelector("#chart4"), options);
    chart.render();
    if (isUpdate) {
      chart.updateOptions({
        series: [{
          data: data
        }]
      })
    }

  }

}
