import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { EventParameters } from '../../models/event-parameters';
import { ProjetService } from "../../service/projet.service";

@Component({
  selector: 'app-event-parameter',
  templateUrl: './event-parameter.component.html',
  styleUrls: ['./event-parameter.component.css']
})
export class EventParamaterComponent implements OnInit, OnChanges {

  constructor(private projectService: ProjetService) { }

  public statusListTemp;
  public dropdownOptions;
  public responsableProfiles;
  public dropdownSettings = {};
  public eventParameters = new EventParameters();
  public alertListEmails: Array<string> = [];
  public responsableListEmails: Array<string> = [];

  @Input('singleEvent') singleEvent: Object;
  @Input('statusList') statusList: Object;
  @Input('eventNamesList') eventNamesList: Array<string>;
  @Output() saveParameters = new EventEmitter();
  @Output() deleteEvent = new EventEmitter();
  ngOnChanges(changes: SimpleChanges): void {
    this.eventNamesList = this.eventNamesList.filter(event => this.singleEvent['name'] !== event['name']);
  }

  ngOnInit() {
    this.dropdownOptions = ['example@example.com'];
    this.responsableProfiles = ['example@example.com'];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      searchPlaceholderText: 'Recherche...',
      textField: 'item_text',
      selectAllText: '',
      unSelectAllText: '',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

  }

  delete(eventName) {
    this.deleteEvent.emit(eventName);

  }


  onItemSelect(email, type) {
    if (type == 'email') {
      this.alertListEmails.push(email);
      this.eventParameters.emailsListNextAutoAlert = this.alertListEmails;
    } else {
      this.responsableListEmails.push(email);
      this.eventParameters.emailsListResponsibles = this.responsableListEmails;
    }

  }

  onItemDeSelect(unSelectedEmail, type) {
    if (type == 'email') {
      this.alertListEmails = this.alertListEmails.filter(email => email !== unSelectedEmail);
      this.eventParameters.emailsListNextAutoAlert = this.alertListEmails;
    } else {
      this.responsableListEmails = this.responsableListEmails.filter(email => email !== unSelectedEmail);
      this.eventParameters.emailsListResponsibles = this.responsableListEmails;
    }

  }
  onSave() {
    this.singleEvent = Object.assign(this.singleEvent, { parameters: this.eventParameters });
    this.saveParameters.emit(this.singleEvent);
  }

  onemailChange(searchTerm, type) {
    if (type == 'emails') {
      this.projectService.getProfiles(searchTerm).subscribe(data => {
        if (data.length) {
          this.dropdownOptions = data;
        }
      });
    } else {
      this.projectService.getProfilesResponsable(searchTerm).subscribe(data => {
        if (data.length) {
          this.responsableProfiles = data;
        }
      });
    }
  }

  onChange(event) {
    this.eventParameters.InitialStatus = { currentStatus: event.target.value };

  }

}
