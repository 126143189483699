import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ENV } from "../../core/env.config";
import { catchError } from "rxjs/operators";
import { ProjetService } from "src/app/service/projet.service";

@Injectable({
  providedIn: 'root'
})
export class PartenaireService {
  baseUri: string = ENV.API_URL;
  headers = new HttpHeaders().set("content-type", "application/json").set("Access-Control-Allow-Origin", "*");

  constructor(private http: HttpClient, private projetService: ProjetService) { }

  searchBase(rrf: string) {
    return this.http.get(`${this.baseUri}/vectury/entities/search?rrf=${rrf}`, { headers: this.headers }).pipe(catchError(this.projetService.handleError));
  }

  loadDMSFromVectury() {
    return this.http.put(`${this.baseUri}/entity/dms`, {}, { headers: this.headers }).pipe(catchError(this.projetService.handleError));
  }

  create(payLoad) {
    return this.http.post(`${this.baseUri}/partenaire`, payLoad, { headers: this.headers }).pipe(catchError(this.projetService.handleError));
  }

  getPartenaireByType(type) {
    return this.http.get(`${this.baseUri}/partenaire?type=${type}`, { headers: this.headers }).pipe(catchError(this.projetService.handleError));
  }

  delete(rrf) {
    return this.http.delete(`${this.baseUri}/partenaire?rrf=${rrf}`, { headers: this.headers }).pipe(catchError(this.projetService.handleError));
  }
  checkIfExists(rrf) {
    return this.http.get(`${this.baseUri}/partenaire/checkIfexists?rrf=${rrf}`, { headers: this.headers }).pipe(catchError(this.projetService.handleError));
  }

  getPartenaireDms(codenature) {
    return this.http.get(`${this.baseUri}/partenaire/dms`, {
      headers: this.headers,
      params: {
        nature: codenature,
      }
    }).pipe(catchError(this.projetService.handleError));
  }
}
